<template>
    <DefaultAppLayout>
        <main class="xl:mr-3 mr-0">
            <div v-if="sale" class="mt-10 lg:mt-0">
                <h2 class="text-lg font-medium text-gray-900">Votre vente {{ sale.name }} du {{ new Date(sale.createdAt).toLocaleDateString("fr-FR") }}</h2>
                <div class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                    <ul role="list" class="divide-y divide-gray-200">
                        <li v-for="item in sale.salesProducts" :key="item.id" class="flex px-4 py-6 sm:px-6">
                            <div class="flex flex-col flex-1 ml-6">
                                <div class="flex">
                                    <div class="flex-1 min-w-0">
                                        <h4 class="text-sm">
                                            <p class="font-medium text-gray-700 hover:text-gray-800">{{ item.product.name }}
                                            </p>
                                        </h4>
                                        <p class="mt-1 text-sm text-gray-500">Client : {{ item.client.name }}</p>
                                        <p class="mt-1 text-sm text-gray-500">Prix : {{ item.price.price }} €</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <dl class="px-4 py-6 space-y-6 border-t border-gray-200 sm:px-6">
                        <div class="flex items-center justify-between">
                            <dt class="text-sm">Nombre de produits</dt>
                            <dd class="text-sm font-medium text-gray-900">{{ sale.nbProduct }}</dd>
                        </div>
                        <div class="flex items-center justify-between">
                            <dt class="text-sm">Pourcentage de bénéfice</dt>
                            <dd class="text-sm font-medium text-gray-900">{{ calculeValuePourcentage(sale.price, sale.benefit) }} %</dd>
                        </div>
                        <div class="flex items-center justify-between">
                            <dt class="text-sm">Charges fixes</dt>
                            <dd class="text-sm font-medium text-gray-900">{{ roundNumbers(sale.expense) }} €</dd>
                        </div>
                        <div class="flex items-center justify-between">
                            <dt class="text-sm">Total commission</dt>
                            <dd class="text-sm font-medium text-gray-900">{{ roundNumbers(sale.commission) }} €</dd>
                        </div>
                        <div class="flex items-center justify-between">
                            <dt class="text-sm">Temps de production (en heures)</dt>
                            <dd class="text-sm font-medium text-gray-900">{{ roundNumbers(sale.time) }} heure<span
                                    v-if="totalTime > 1">s</span>
                            </dd>
                        </div>
                        <div class="flex items-center justify-between">
                            <dt class="text-sm">Total URSAF</dt>
                            <dd class="text-sm font-medium text-gray-900">{{ roundNumbers(sale.ursaf) }} €</dd>
                        </div>
                        <div class="flex items-center justify-between pt-6 border-t border-gray-200">
                            <dt class="text-sm">Bénéfice</dt>
                            <dd class="text-sm font-medium text-gray-900">{{ roundNumbers(sale.benefit) }} €</dd>
                        </div>
                        <div class="flex items-center justify-between ">
                            <dt class="text-base font-medium">Chiffre d'affaires</dt>
                            <dd class="text-base font-medium text-gray-900">{{ roundNumbers(sale.price) }} €</dd>
                        </div>

                    </dl>
                </div>
            </div>
        </main>
    </DefaultAppLayout>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { roundNumbers } from '@/utils/RoundNumbers';
import { calculeValuePourcentage } from '@/utils/UtilsCacul';

const route = useRoute();

import DefaultAppLayout from '@/components/Layout/DefaultAppLayout.vue'
import { GetSaleById } from "@/api/services/SalesServiceFunctions";

const sale = ref(null);

const fecthDatas = async () => {
    const saleId = route.params.id;

    sale.value = await GetSaleById(saleId);
};

onMounted(() => {
    fecthDatas();
});
</script>