<template>
    <div class="flex xl:flex-row flex-col xl:space-x-3  xl:space-y-0 space-y-3 xl:items-center justify-between w-full">
        <div class="flex items-center mt-2">
            <SwitchGroup as="div" class="flex items-center">
                <Switch v-model="localIsYears"
                    :class="[localIsYears ? 'bg-purple-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2']">
                    <span aria-hidden="true"
                        :class="[localIsYears ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                </Switch>
                <SwitchLabel as="span" class="ml-3 text-sm">
                    <span class="font-medium text-gray-900">Données annuelles</span>
                </SwitchLabel>
            </SwitchGroup>
        </div>

        <div class="flex justify-between space-x-3">
            <!-- Month Selector -->
            <Listbox v-model="localMonthSelected" v-if="!localIsYears">
                <div class="relative mt-2 w-full xl:w-auto">
                    <ListboxButton
                        class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 sm:text-sm sm:leading-6">
                        <span class="block truncate">{{ localMonthSelected.name }}</span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <ChevronUpDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </ListboxButton>

                    <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                        leave-to-class="opacity-0">
                        <ListboxOptions
                            class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            <ListboxOption v-for="item in months" :key="item.id" :value="item"
                                v-slot="{ active, selected }">
                                <li
                                    :class="[active ? 'bg-purple-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
                                        item.name }}</span>
                                    <span v-if="selected"
                                        :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                        <CheckIcon class="w-5 h-5" aria-hidden="true" />
                                    </span>
                                </li>
                            </ListboxOption>
                        </ListboxOptions>
                    </transition>
                </div>
            </Listbox>

            <!-- Year Selector -->
            <Listbox v-model="localYearSelected">
                <div class="relative mt-2 w-full xl:w-auto">
                    <ListboxButton
                        class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 sm:text-sm sm:leading-6">
                        <span class="block truncate">{{ localYearSelected.name }}</span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <ChevronUpDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </ListboxButton>

                    <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                        leave-to-class="opacity-0">
                        <ListboxOptions
                            class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            <ListboxOption v-for="item in years" :key="item.name" :value="item"
                                v-slot="{ active, selected }">
                                <li
                                    :class="[active ? 'bg-purple-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
                                        item.name }}</span>
                                    <span v-if="selected"
                                        :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                        <CheckIcon class="w-5 h-5" aria-hidden="true" />
                                    </span>
                                </li>
                            </ListboxOption>
                        </ListboxOptions>
                    </transition>
                </div>
            </Listbox>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';

const props = defineProps({
    months: Array,
    years: Array,
    productData: Object,
    monthSelected: Object,
    yearSelected: Object,
    isYears: Boolean,
});

const emit = defineEmits(['update:isYears', 'update:monthSelected', 'update:yearSelected']);

// Local state to handle v-model
const localIsYears = ref(props.isYears);
const localMonthSelected = ref(props.monthSelected);
const localYearSelected = ref(props.yearSelected);

// Watchers to emit changes to parent component
watch(localIsYears, (newValue) => {
    emit('update:isYears', newValue);
});

watch(localMonthSelected, (newValue) => {
    emit('update:monthSelected', newValue);
});

watch(localYearSelected, (newValue) => {
    emit('update:yearSelected', newValue);
});
</script>