<template>
    <div class="flow-root w-full h-full">
        <table class="w-full">
            <colgroup>
                <col />
                <col />
                <col />
                <col />
            </colgroup>
            <slot></slot>
        </table>
    </div>
</template>