<template>
    <TransitionRoot :show="open" as="template" @after-leave="query = ''" appear>
        <Dialog class="relative z-10">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-25" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen p-4 overflow-y-auto sm:p-6 md:p-20">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95">
                    <DialogPanel
                        class="max-w-3xl mx-auto overflow-hidden transition-all transform bg-white divide-y divide-gray-100 shadow-2xl rounded-xl ring-1 ring-black ring-opacity-5">
                        <Combobox v-slot="{ activeOption }" @update:modelValue="onSelect">
                            <div class="relative">
                                <MagnifyingGlassIcon
                                    class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true" />
                                <ComboboxInput
                                    class="w-full h-12 pr-4 text-gray-900 bg-transparent border-0 pl-11 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                    placeholder="Chercher un programme..." @input="updateQuery($event)"
                                    @blur="query = ''" />
                            </div>

                            <ComboboxOptions v-if="query === '' || filteredPeople.length > 0"
                                class="flex divide-x divide-gray-100 transform-gpu" as="div" static hold>
                                <div
                                    :class="['max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4', activeOption && 'sm:h-96']">
                                    <h2 v-if="query === ''" class="mt-2 mb-4 text-xs font-semibold text-gray-500">Tous
                                        vos produit</h2>
                                    <div hold class="-mx-2 text-sm text-gray-700">
                                        <ComboboxOption v-for="item in filteredPeople" :key="item.name" :value="item"
                                            as="template" v-slot="{ active }">
                                            <div v-if="!item.isArchived"
                                                :class="['group flex cursor-default select-none items-center rounded-md p-2', active && 'bg-gray-100 text-gray-900']">
                                                <span class="flex-auto ml-3 truncate">{{ item.name }}</span>
                                                <ChevronRightIcon v-if="active"
                                                    class="flex-none w-5 h-5 ml-3 text-gray-400" aria-hidden="true" />
                                            </div>
                                            <div v-else></div>
                                        </ComboboxOption>
                                    </div>
                                </div>

                                <div v-if="activeOption"
                                    class="flex-col flex-none hidden w-1/2 overflow-y-auto divide-y divide-gray-100 h-96 sm:flex">
                                    <div class="flex-none p-6 text-center">
                                        <h2 class="mt-3 font-semibold text-gray-900">
                                            {{ activeOption.name }}
                                        </h2>
                                    </div>
                                    <div class="flex flex-col justify-between flex-auto p-6">
                                        <dl class="grid grid-cols-1 text-sm text-gray-700 gap-x-6 gap-y-3">
                                            <dt class="col-end-1 font-semibold text-gray-900">Catégories</dt>
                                            <dd class="flex space-x-3"><span v-for="category in activeOption.category"
                                                    :key="category.id">{{ category.name }}</span>
                                            </dd>
                                            <dt class="col-end-1 font-semibold text-gray-900">Prix</dt>
                                            <dd class="flex space-x-3"><span v-for="price in activeOption.prices"
                                                    :key="price.id">{{ price.name }} : {{ price.price }}</span>
                                            </dd>
                                        </dl>
                                        <button type="button" @click="selectProductAction(activeOption)"
                                            class="w-full px-3 py-2 mt-6 text-sm font-semibold text-white rounded-md shadow-sm bg-slate-600 hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">
                                            Ajouter le produit
                                        </button>
                                    </div>
                                </div>
                            </ComboboxOptions>

                            <div v-if="query !== '' && filteredPeople.length === 0"
                                class="px-6 text-sm text-center py-14 sm:px-14">
                                <GiftIcon class="w-6 h-6 mx-auto text-gray-400" aria-hidden="true" />
                                <p class="mt-4 font-semibold text-gray-900">Aucun produit trouvé</p>
                                <!-- <p class="mt-2 text-gray-500">We couldn’t find anything with that term. Please try
                                    again.</p> -->
                            </div>
                        </Combobox>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { computed, ref, defineProps } from 'vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import { ChevronRightIcon, GiftIcon } from '@heroicons/vue/24/outline'
import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'

const props = defineProps({
    data: Array,
    open: Boolean,
    selectProductAction: Function
})

const query = ref('')

const filteredPeople = computed(() => query.value === ''
    ? props.data
    : props.data.filter((item) => item.name.toLowerCase().includes(query.value.toLowerCase()))
)

function updateQuery(event) {
    query.value = event.target.value
}

function onSelect(person) {
    if (person) {
        props.selectProductAction(person)
    }
}
</script>