// SaleServiceFunctions.js
import axios from "@/api/config";
import { toast } from "vue-sonner";
import { GetCallApi, GetCallSingleApi } from "@/api/services/utilsApi";

export const GetAllSaleData = async (filter) => {
  const url = filter ? "/sales?" + filter : "/sales";
  return await GetCallApi(url);
};

// Récupérer un sale par ID
export const GetSaleById = async (id, filter) => {
  const url = filter ? `/sales/${id}?` + filter : `/sales/${id}`;
  return await GetCallSingleApi(url);
};

// Créer un nouveau produit
export const CreateSale = async (saleData) => {
  try {
    const response = await axios.post("/sales", saleData);
    toast.success("Vente créé avec succès");
    return response;
  } catch (error) {
    console.error(error);
    toast.error("Une erreur est survenue");
  }
};

// Mettre à jour un produit
export const UpdateSale = async (id, saleData) => {
  try {
    const response = await axios.put(`/sales/${id}`, saleData);
    toast.success("Sale updated successfully");
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("Failed to update sale");
  }
};

// Supprimer un produit
export const DeleteSale = async (id) => {
  try {
    await axios.delete(`/sales/${id}`);
    toast.success("Sale deleted successfully");
  } catch (error) {
    console.error(error);
    toast.error("Failed to delete sale");
  }
};
