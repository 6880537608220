<template>
    <div class="flex justify-center w-full h-screen item-center">
        <div class="flex flex-col justify-center item-center">
            <div class="flex">
                <div class="w-3 h-3 mr-1 bg-current rounded-full animate-bounce"></div>
                <div class="w-3 h-3 mr-1 bg-current rounded-full animate-bounce200"></div>
                <div class="w-3 h-3 bg-current rounded-full animate-bounce400"></div>
            </div>
        </div>
    </div>
</template>