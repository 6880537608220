<template>
    <DefaultAppLayout>
        <div class="p-5">
            <div class="flex items-center justify-between">
                <h2 class="font-bold tracking-tight text-gray-900 text-22">Toutes vos ventes</h2>
                <DefaultLinkBtn to="/create-sale" title="Créer une ventes" />
            </div>
            <DefaultBox customClass="mt-6 overflow-hidden border-t border-gray-100">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col"
                                        class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-12 font-semibold text-gray-900 sm:pl-0">
                                        ID Ventes</th>
                                    <th scope="col"
                                        class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-12 font-semibold text-gray-900 sm:pl-0 hidden">
                                        Date</th>
                                    <th scope="col"
                                        class="whitespace-nowrap px-2 py-3.5 text-left text-12 font-semibold text-gray-900">
                                        Canal de vente</th>
                                    <th scope="col"
                                        class="whitespace-nowrap px-2 py-3.5 text-left text-12 font-semibold text-gray-900 hidden ">
                                        Prix</th>
                                    <th scope="col"
                                        class="whitespace-nowrap px-2 py-3.5 text-left text-12 font-semibold text-gray-900 hidden ">
                                        Bénéfice</th>
                                    <th scope="col"
                                        class="whitespace-nowrap px-2 py-3.5 text-left text-12 font-semibold text-gray-900 hidden ">
                                        Pourcentage bénéfice</th>
                                    <th scope="col"
                                        class="whitespace-nowrap px-2 py-3.5 text-left text-12 font-semibold text-gray-900 hidden ">
                                        Nombre de produit</th>
                                    <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span class="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="sale in sales" :key="sale.id">
                                    <td class="py-2 pl-4 pr-3 text-gray-500 whitespace-nowrap text-12 sm:pl-0">
                                        {{ sale.name }}</td>
                                    <td class="py-2 pl-4 pr-3 text-gray-500 whitespace-nowrap text-12 sm:pl-0 hidden ">
                                        {{ new Date(sale.createdAt).toLocaleDateString("fr-FR") }}</td>
                                    <td class="px-2 py-2 font-medium text-gray-900 whitespace-nowrap text-12">{{
                                    sale.canal.name }}</td>
                                    <td class="px-2 py-2 text-gray-500 whitespace-nowrap text-12 hidden ">{{
                                    sale.price }} €</td>
                                    <td class="px-2 py-2 text-gray-500 whitespace-nowrap text-12 hidden ">{{
                                    sale.benefit }}</td>
                                    <td class="px-2 py-2 text-gray-500 whitespace-nowrap text-12 hidden ">{{
                                    calculeValuePourcentage(sale.price, sale.benefit) }} %</td>
                                    <td class="px-2 py-2 text-gray-500 whitespace-nowrap text-12 hidden ">{{
                                    sale.nbProduct }}</td>
                                    <td
                                        class="relative py-2 pl-3 pr-4 font-medium text-right whitespace-nowrap text-12 sm:pr-0">
                                        <RouterLink :to="`/sale/${sale.id}`"
                                            class="text-purple-600 hover:text-purple-900">Voir<span class="sr-only">, {{
                                                sale.id }}</span></RouterLink>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </DefaultBox>
        </div>
    </DefaultAppLayout>
</template>

<script setup>
import { ref, onMounted } from 'vue';

import { RouterLink } from 'vue-router';

import { calculeValuePourcentage } from '@/utils/UtilsCacul'
import DefaultAppLayout from '@/components/Layout/DefaultAppLayout.vue'
import DefaultBox from '@/components/Layout/DefaultBox.vue'
import DefaultLinkBtn from '@/components/Button/DefaultLinkBtn.vue'
import { GetAllSaleData } from '@/api/services/SalesServiceFunctions'

const sales = ref([]);

const fetchSales = async () => {
    sales.value = await GetAllSaleData("order%5BcreatedAt%5D=desc");
};

onMounted(() => {
    fetchSales();
});
</script>