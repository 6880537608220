<template>
    <div>
        <div class="mt-3 text-center sm:mt-5">
            <DialogTitle as="h3" class="font-semibold leading-6 text-gray-900 text-24">Bienvenue sur Maker Copilot
            </DialogTitle>
            <div class="mt-5">
                <p class="text-gray-500 text-14">Fini les feuilles de calcul compliquées ! Découvrez l'outil ultime pour
                    les créateurs. Suivez vos ventes, analysez vos bénéfices, fixez des objectifs ambitieux et accédez à
                    des statistiques détaillées. Simplifiez la gestion de votre business, gagnez du temps et augmentez
                    votre chiffre d'affaire !</p>
            </div>
        </div>
    </div>
</template>
