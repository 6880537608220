// CategoryServiceFunctions.js
import axios from "@/api/config";
import { toast } from "vue-sonner";
import { GetCallApi, GetCallSingleApi } from "@/api/services/utilsApi";

// Récupérer tous les produits
export const GetAllCategoryData = async () => {
  return await GetCallApi("/categories?page=1&itemsPerPage=1000");
};

// Récupérer un produit par ID
export const GetCategoryById = async (id) => {
  return await GetCallSingleApi(`/categories/${id}`);
};

// Créer un nouveau produit
export const CreateCategory = async (categoryData) => {
  try {
    const response = await axios.post("/categories", categoryData);
    toast.success("Catégorie créé avec succès !");
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("Une erreur est survenue");
  }
};

// Mettre à jour un produit
export const UpdateCategory = async (id, categoryData) => {
  try {
    const response = await axios.put(`/categories/${id}`, categoryData);
    toast.success("Category updated successfully");
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("Failed to update product");
  }
};

export const PatchCategory = async (id, categoryData) => {
  try {
    const response = await axios.patch(`/categories/${id}`, categoryData);
    toast.success("Category updated successfully");
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("Failed to update product");
  }
};

// Supprimer un produit
export const DeleteCategory = async (id) => {
  try {
    await axios.delete(`/categories/${id}`);
    toast.success("Category deleted successfully");
  } catch (error) {
    console.error(error);
    toast.error("Failed to delete product");
  }
};
