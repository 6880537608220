<template>
    <div v-if="isTrue" class="mt-3 text-center sm:mt-5">
        <div class="flex flex-col">
            <DialogTitle as="h3" class="font-semibold leading-6 text-gray-900 text-24">Bienvenue sur Maker Copilot
            </DialogTitle>
            <button @click="updateOpen" type="submit"
                class="px-3 py-2 mx-auto mt-24 font-semibold bg-transparent border rounded-md w-fit text-12 hover:bg-c_gray border-slate-800 text-slate-800">
                Entrer dans Maker Copilot
            </button>
        </div>
        <ConfettiExplosion :particleCount="200" :force="0.3" :duration="5000" />
    </div>
    <div v-else class="mt-3 text-center sm:mt-5">
        <div class="flex flex-col items-center">
            <div class="flex items-center justify-center flex-shrink-0 w-16 h-16 mx-auto bg-red-100 rounded-full">
                <ExclamationTriangleIcon class="w-10 h-10 text-red-600" aria-hidden="true" />
            </div>
            <DialogTitle as="h3" class="mt-10 font-semibold leading-6 text-gray-900 text-24">Vous devez remplir tous les
                champs de chaque étape.
            </DialogTitle>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, defineProps } from "vue"
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

import ConfettiExplosion from "vue-confetti-explosion";

defineProps({
    updateOpen: Function,
})

const isTrue = ref(false)

const validateElement = () => {
    const valueObjectif = localStorage.getItem("objectifValue")
    const urssafPourcent = localStorage.getItem("urssafPourcent")
    const urssafType = localStorage.getItem("urssafType")

    if (valueObjectif != 'null' && urssafPourcent != 'null' && urssafType != 'null') {
        isTrue.value = true
    }
}

onMounted(() => {
    validateElement()
})
</script>