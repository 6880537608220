// PriceServiceFunctions.js
import axios from "@/api/config";
import { toast } from "vue-sonner";

// Créer un nouveau produit
export const CreatePrice = async (priceData) => {
  try {
    const response = await axios.post("/prices", priceData);
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("Une erreur est survenue");
  }
};

export const PatchPrice = async (id, priceData) => {
  try {
    const response = await axios.patch(`/prices/${id}`, priceData);
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("Une erreur est survenue");
  }
};
