// SalesChannelServiceFunctions.js
import axios from "@/api/config";
import { toast } from "vue-sonner";
import { GetCallApi, GetCallSingleApi } from "@/api/services/utilsApi";

// Récupérer tous les produits
export const GetAllSalesChannelData = async () => {
  return await GetCallApi("/sales_channels?page=1&itemsPerPage=1000");
};

// Récupérer un produit par ID
export const GetSalesChannelById = async (id) => {
  return await GetCallSingleApi(`/sales_channels/${id}`);
};

// Créer un nouveau produit
export const CreateSalesChannel = async (clientData) => {
  try {
    const response = await axios.post("/sales_channels", clientData);
    toast.success("Canal de vente créé avec succès !");
    return response;
  } catch (error) {
    console.error(error);
    toast.error("Une erreur est survenue");
  }
};

// Mettre à jour un produit
export const UpdateSalesChannel = async (id, clientData) => {
  try {
    const response = await axios.put(`/sales_channels/${id}`, clientData);
    toast.success("SalesChannel updated successfully");
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("Failed to update client");
  }
};

// Supprimer un produit
export const DeleteSalesChannel = async (id) => {
  try {
    await axios.delete(`/sales_channels/${id}`);
    toast.success("SalesChannel deleted successfully");
  } catch (error) {
    console.error(error);
    toast.error("Failed to delete client");
  }
};
