<template>
    <div class="mt-3 text-center">
        <form @submit.prevent="updateUser">
            <DialogTitle as="h3" class="font-semibold leading-6 text-gray-900 text-24">
                Ajouter un l'objectif mensuel de CA
            </DialogTitle>
            <div class="w-full py-7">
                <label for="email-address" class="block text-sm font-medium text-left text-gray-700">
                    Valeur de l'objectif mensuel
                </label>
                <div class="mt-2">
                    <input type="number" name="nom" autocomplete="off" v-model="valueInput"
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:border-purple-500 focus:ring-purple-500 text-10" />
                </div>
            </div>
            <div class="flex mt-5 space-x-3">
                <DefaultActionBtn title="Sauvegarder" />
            </div>
        </form>
    </div>
</template>

<script setup>
import { ref } from 'vue'

import DefaultActionBtn from '@/components/Button/DefaultActionBtn.vue'
import { UpdateUser } from '@/api/services/UserServiceFunctions';
import authService from "@/api/services/authService";
import { defineProps } from 'vue'

const props = defineProps({
    updatePageItem: Function,
})

const valueInput = ref(null)

const updateUser = () => {
    try {
        UpdateUser(authService.getUser().id, {
            objectifValue: valueInput.value,
        })
        localStorage.setItem("objectifValue", valueInput.value);
        props.updatePageItem()
    } catch (error) {
        console.error(error)
    }
}
</script>
