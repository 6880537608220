<template>
    <DefaultAppLayout>
        <div class="p-5" v-if="isUpdated !== null">
            <div class="flex items-center justify-between mb-3">
                <h1 v-if="!isUpdated" class="font-semibold text-24 text-slate-800">Créer un produit</h1>
                <h1 v-else class="font-semibold text-24 text-slate-800">Mettre à jours de {{ dataProduct.name }}
                </h1>
                <div v-if="isLargeScreen" class="flex items-center space-x-3">
                    <DefaultLinkBtn title="Annuler" to="/products" />
                    <DefaultActionBtn title="Sauvegarder" :onClick="clickCreateProduct" />
                </div>
            </div>
            <div class="flex space-x-3">
                <div class="xl:w-[75%] w-full flex flex-col space-y-3">
                    <DefaultBox customClass="w-full flex xl:flex-row flex-col space-y-3 xl:space-y-0 xl:space-x-3">
                        <div class="xl:w-1/2 w-full">
                            <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Nom du
                                produit</label>
                            <div class="mt-2">
                                <div
                                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-600 sm:max-w-md">
                                    <input type="text" name="name" id="name" autocomplete="name"
                                        v-model="dataProduct.name"
                                        class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        placeholder="Lorem ipsum" />
                                </div>
                            </div>
                        </div>

                        <div class="xl:w-1/2 w-full">
                            <DefaultMultipleSelect label="Catégories" :data="categories" v-if="!isUpdated"
                                v-model:selectedData="selectedCatagory" />
                        </div>
                    </DefaultBox>

                    <DefaultBox customClass="w-full">
                        <h2 class="text-lg font-medium text-gray-900">Gestion de vos bénéfices</h2>
                        <ProductTable :addRowTable="addRowTable" :removePerson="removePerson" :price="price"
                            :query="query" />
                    </DefaultBox>
                </div>
                <div v-if="isLargeScreen" class="w-[25%] flex flex-col space-y-3">
                    <NumberCard v-if="benefitMonth" :data="benefitMonth" title="Votre bénéfice moyen"
                        :subtitle="`en ${benefitMonth.years}`" :value="roundNumbers(benefitMonth.benefit_pourcent)"
                        unity="%" :subvalue="`${roundNumbers(benefitMonth.benefit_value)} €`"
                        :subtitlevalue="`en ${benefitMonth.years}`" changeType="increase" change="0" />
                    <NoDataCard v-else />
                    <DefaultBox>
                        <h2 class="font-bold tracking-tight text-gray-900 text-24">Support
                            center</h2>
                        <p class="mt-3 text-lg leading-8 text-gray-600">Anim aute id magna aliqua ad ad non
                            deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat
                            veniam occaecat fugiat aliqua.</p>
                    </DefaultBox>
                    <DefaultBox>
                        <h2 class="font-bold tracking-tight text-gray-900 text-24">Support
                            center</h2>
                        <p class="mt-3 text-lg leading-8 text-gray-600">Anim aute id magna aliqua ad ad non
                            deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat
                            veniam occaecat fugiat aliqua. deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit
                            sunt amet fugiat
                            veniam occaecat fugiat aliqua. deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit
                            sunt amet fugiat
                            veniam occaecat fugiat aliqua.</p>
                    </DefaultBox>
                </div>
            </div>
            <div v-if="!isLargeScreen" class="flex items-center space-x-3 mt-10">
                <DefaultLinkBtn title="Annuler" to="/products" />
                <DefaultActionBtn title="Sauvegarder" :onClick="clickCreateProduct" />
            </div>
        </div>
        <DefaultLoading v-else />
    </DefaultAppLayout>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { jwtDecode } from "jwt-decode";
import { toast } from "vue-sonner";
import { useRoute } from 'vue-router';

const route = useRoute();

import DefaultAppLayout from '@/components/Layout/DefaultAppLayout.vue';
import DefaultLinkBtn from '@/components/Button/DefaultLinkBtn.vue';
import DefaultActionBtn from '@/components/Button/DefaultActionBtn.vue';
import DefaultBox from '@/components/Layout/DefaultBox.vue';
import DefaultMultipleSelect from '@/components/Select/DefaultMultipleSelect.vue';
import ProductTable from '@/components/Table/Other/ProductTable.vue';
import NumberCard from '@/components/Ui/NumberCard.vue';
import DefaultLoading from '@/components/Ui/DefaultLoading.vue';
import NoDataCard from '@/components/Other/NoDataCard';
import router from '@/router';

import { GetAllCategoryData } from '@/api/services/CategoryServiceFunctions';
import { CreateProduct, GetProductById, PatchProduct } from '@/api/services/ProductServiceFunctions';
import { CreatePrice } from '@/api/services/PriceServiceFunctions';
import { GetAllViewBenefitYear } from '@/api/services/StatViewService';
import { roundNumbers } from '@/utils/RoundNumbers';
import { useScreenStore } from '@/stores/useScreenStore'

const screenStore = useScreenStore();

// Initialize the screen size listener
screenStore.initializeScreenSizeListener();

const isLargeScreen = screenStore.isLargeScreen;

const token = localStorage.getItem("token") || null;
const decoded = token ? jwtDecode(token) : null;

const categories = ref([]);
const selectedCatagory = ref([]);
const isUpdated = ref(null);
const benefitMonth = ref(null);

const price = ref([
    {
        name: 'Shop 01',
        price: 0,
        ursaf: Number(localStorage.getItem('urssafPourcent')),
        expense: 0,
        commission: 0,
        benefit: 0,
        time: 0,
    },
])

watch(
    price,
    (newValue) => {
        newValue.forEach(item => {
            item.benefit =
                Math.round((item.price - (item.price * (item.ursaf / 100)) - (item.price * (item.commission / 100)) - item.expense) * 100) / 100
        });
    },
    { deep: true }
);

const dataProduct = ref({
    name: null,
    imageName: "string",
    user: "api/users/" + decoded.id,
});

const addRowTable = () => {
    price.value.push({
        name: 'Shop ' + Math.round(Math.random() * 100),
        price: price.value[0]?.price || 0,
        ursaf: Number(localStorage.getItem('urssafPourcent')),
        expense: price.value[0]?.expense || 0,
        commission: price.value[0]?.commission || 0,
        benefit: price.value[0]?.benefit || 0,
        time: price.value[0]?.time || 0,
    })
}

const removePerson = (name, status) => {
    if (status === 1) {
        price.value = price.value.filter(person => person.name !== name)
    } else if (status === 2) {
        console.log('Hello')
    } else if (status === 3) {
        console.log('Hello')
    }
}

const fetchCategories = async () => {
    categories.value = await GetAllCategoryData();
};

// If product existe for update
const fetchDataProduct = async () => {
    const productId = route.params.id;

    if (productId) {
        const responseProduct = await GetProductById(productId);
        if (responseProduct) {
            dataProduct.value = {
                name: responseProduct.name,
                user: "api/users/" + decoded.id,
            }
            price.value = responseProduct.prices
            selectedCatagory.value = responseProduct.category
            isUpdated.value = true
        } else {
            isUpdated.value = false
        }
    } else {
        isUpdated.value = false
    }
};

const fetchBenefitProduct = async () => {
    const date = new Date();
    let nowYears = date.getFullYear();
    const productId = route.params.id
    const response = await GetAllViewBenefitYear(`product_id=${productId}&years=${nowYears}`);
    benefitMonth.value = response[0]
};

const clickCreateProduct = async () => {
    if (dataProduct.value.name && price.value.length > 0) {
        try {
            dataProduct.value.prices = price.value
            const categoryId = selectedCatagory.value
                .filter((item) => item['@id'])
                .map((item) => item['@id']);
            dataProduct.value.category = categoryId
            let response = null
            if (isUpdated.value) {
                const prices = dataProduct.value.prices
                const newPrices = prices.filter((item) => !item['@id'])

                newPrices.map((item) => {
                    item.product = "api/products/" + route.params.id
                    CreatePrice(item)
                })
                response = await PatchProduct(route.params.id, {
                    name: dataProduct.value.name
                });

                router.push({ path: '/products' })
            } else {
                response = await CreateProduct(dataProduct.value);
            }
            if (response.status === 201) {
                router.push({ path: '/products' })
            }
        } catch (error) {
            console.error(error);
            toast.error("Une erreur est survenue");
        }
    } else {
        toast.error("Vous devez remplire le champs nom du produit et mettre des prix");

    }
};

onMounted(() => {
    fetchCategories();
    fetchDataProduct();
    fetchBenefitProduct();
});
</script>
