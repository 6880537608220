// api/config.js
import axios from "axios";
import authService from "@/api/services/authService";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/api",
  headers: {
    "Content-language": "fr",
  },
});

// Ajoutez un intercepteur pour mettre à jour le token avant chaque requête
apiClient.interceptors.request.use((config) => {
  console.log(config);
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (config.method === "patch") {
    config.headers["Content-Type"] = "application/merge-patch+json";
  } else {
    config.headers["Content-Type"] = "application/ld+json";
  }
  return config;
});

// intercept error responses 401 and 403 and redirect to login page
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    console.log("error", error);
    if (error.response.status === 401 && !originalRequest._retry) {
      // alert(error.response.status);
      console.log(error);
      console.log("response", error.response);
      originalRequest._retry = true;
      authService.logout();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default apiClient;
