// ClientServiceFunctions.js
import axios from "@/api/config";
import { toast } from "vue-sonner";
import { GetCallApi, GetCallSingleApi } from "@/api/services/utilsApi";

// Récupérer tous les produits
export const GetAllClientData = async () => {
  return await GetCallApi("/clients?page=1&itemsPerPage=1000");
};

// Récupérer un produit par ID
export const GetClientById = async (id) => {
  return await GetCallSingleApi(`/clients/${id}`);
};

// Créer un nouveau produit
export const CreateClient = async (clientData) => {
  try {
    const response = await axios.post("/clients", clientData);
    toast.success("Produit créé avec succès");
    return response;
  } catch (error) {
    console.error(error);
    toast.error("Une erreur est survenue");
  }
};

// Mettre à jour un produit
export const UpdateClient = async (id, clientData) => {
  try {
    const response = await axios.put(`/clients/${id}`, clientData);
    toast.success("Client updated successfully");
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("Failed to update client");
  }
};

// Supprimer un produit
export const DeleteClient = async (id) => {
  try {
    await axios.delete(`/clients/${id}`);
    toast.success("Client deleted successfully");
  } catch (error) {
    console.error(error);
    toast.error("Failed to delete client");
  }
};
