<template>
    <nav
        :class="['flex flex-1 fixed bg-c_gray rounded-full z-50 p-2', isLargeScreen ? 'flex-col left-5 top-[32%] space-y-3' : 'bottom-3 justify-between items-center left-[50%] right-[50%] -translate-x-1/2 -translate-y-1/2 w-[90vw]']">
        <div v-for="(item, index) in navigation" :key="item.name" @mouseover="upHere[index] = true"
            @mouseleave="upHere[index] = false">
            <RouterLink :to="item.href"
                :class="[item.current ? 'bg-c_smoke_white' : 'hover:bg-c_smoke_white', 'group flex items-center gap-x-3 rounded-full p-3 text-12 font-semibold leading-6 text-gray-700']">
                <component :is="item.icon" class="w-5 h-5 shrink-0 text-slate-800" aria-hidden="true" />
            </RouterLink>
        </div>
    </nav>
</template>

<script setup>
import { ref } from 'vue';
import { RouterLink } from 'vue-router';
import { useScreenStore } from '@/stores/useScreenStore'

const screenStore = useScreenStore();

// Initialize the screen size listener
screenStore.initializeScreenSizeListener();

const isLargeScreen = screenStore.isLargeScreen;

import {
    ChartPieIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    CurrencyEuroIcon,
    // MinusCircleIcon,
    Cog8ToothIcon
} from '@heroicons/vue/24/outline';

const navigation = [
    { name: 'Accueil', href: '/dashboard', icon: HomeIcon, current: window.location.pathname.includes('dashboard') ? true : false },
    { name: 'Produits', href: '/products', icon: FolderIcon, current: window.location.pathname.includes('product') ? true : false },
    { name: 'Ventes', href: '/sales', icon: CurrencyEuroIcon, current: window.location.pathname.includes('sale') ? true : false },
    // { name: 'Dépenses', href: '/depense', icon: MinusCircleIcon, current: window.location.pathname.includes('depense') ? true : false },
    { name: 'Clients', href: '/clients', icon: UsersIcon, current: window.location.pathname.includes('client') ? true : false },
    { name: 'Rapports', href: '/rapports', icon: ChartPieIcon, current: window.location.pathname.includes('rapport') ? true : false },
    { name: 'Paramètre', href: '/settings', icon: Cog8ToothIcon, current: window.location.pathname.includes('setting') ? true : false },
];

const upHere = ref(navigation.map(() => false));
</script>
