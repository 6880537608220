<template>
    <div class="py-24 sm:py-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl lg:text-center">
                <h2 class="text-base font-semibold leading-7 text-purple-600">Engagements des testeurs</h2>
                <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Contribuez activement à
                    l'amélioration de Maker Copilot</p>
                <p class="mt-6 text-lg leading-8 text-gray-600">
                    En tant que testeur, votre rôle est essentiel pour le développement de notre plateforme. Voici vos
                    principaux engagements :
                </p>
            </div>
            <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                    <div v-for="engagement in engagements" :key="engagement.name" class="relative pl-16">
                        <dt class="text-base font-semibold leading-7 text-gray-900">
                            <div
                                class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-600">
                                <component :is="engagement.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            {{ engagement.name }}
                        </dt>
                        <dd class="mt-2 text-base leading-7 text-gray-600">{{ engagement.description }}</dd>
                    </div>
                </dl>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ChatBubbleLeftRightIcon, DocumentMagnifyingGlassIcon, LightBulbIcon, CheckBadgeIcon } from '@heroicons/vue/24/outline'

const engagements = [
    {
        name: 'Répondre aux questionnaires',
        description: 'Vous devez répondre aux questionnaires envoyés par email, au moins une fois par an pour nous aider à mieux comprendre vos besoins.',
        icon: DocumentMagnifyingGlassIcon,
    },
    {
        name: 'Donner votre avis sur les nouvelles fonctionnalités',
        description: 'Pour chaque nouvelle fonctionnalité, partagez votre avis et retour d’expérience afin de nous aider à les améliorer.',
        icon: ChatBubbleLeftRightIcon,
    },
    {
        name: 'Proposer des améliorations',
        description: 'Proposez régulièrement des améliorations et des suggestions pour rendre la plateforme encore plus efficace et adaptée à vos besoins.',
        icon: LightBulbIcon,
    },
    {
        name: 'Tester les mises à jour avant leur lancement',
        description: 'Participez aux tests des mises à jour avant leur lancement officiel pour identifier les bugs et assurer une expérience utilisateur optimale.',
        icon: CheckBadgeIcon,
    },
]
</script>
