<template>
    <div class="w-full">
        <DefaultBox customClass="h-[300px] w-full flex flex-col justify-between">
            <HeaderArrowNext :title="title" :subtitle="subtitle" to="/" :icon="ChartBarIcon" />
            <div class="flex flex-col items-end justify-center h-full">
                <p class="text-12">Pour {{ data.price_value }}€ de CA sur le produit</p>
                <div v-if="pourcent.length > 0" class="flex flex-col w-full space-y-3">
                    <div class="flex w-full p-1 space-x-1 bg-c_gray rounded-xl">
                        <div v-for="(item, index) in pourcent" :key="index"
                            :style="`width: ${item}%`">
                            <div :class="[colors[index + 1], 'rounded-xl w-full h-16']" />
                        </div>
                    </div>
                    <div class="grid w-full grid-cols-4 gap-3">
                        <div v-for="(item, index) in pourcent" :key="index" class="flex space-x-2">
                            <div :class="[colors[index + 1], 'w-3 h-full rounded-xl']" />
                            <div class="flex flex-col ">
                                <p class="font-semibold text-14 text-slate-600">{{ returnName(index + 1).name }}
                                </p>
                                <p class="text-12 text-slate-700">{{ roundNumbers(returnName(index + 1).value) }} € /
                                    {{ roundNumbers(item) }}%
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DefaultBox>
    </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue'

import DefaultBox from '@/components/Layout/DefaultBox.vue'
import HeaderArrowNext from '@/components/Header/HeaderArrowNext.vue'
import { ChartBarIcon } from '@heroicons/vue/20/solid'
import { calculeValuePourcentage, calculePourcentValuePourcentage } from '@/utils/UtilsCacul';
import { roundNumbers } from '@/utils/RoundNumbers';
import { getGoodValueUrssaf } from '@/utils/GetUrssafValue';

const colors = {
    1: 'bg-c_green',
    2: 'bg-c_purple',
    3: 'bg-c_light_bleu',
    4: 'bg-amber-500',
}

const pourcent = ref([])

const props = defineProps({
    data: Array,
    title: String,
    subtitle: String,
});

const returnName = ((value) => {
    let name = null
    let localValue = null
    switch (value) {
        case 1:
            name = "Dépense"
            localValue = props.data.expense_value
            break;
        case 2:
            name = "Urssaf"
            localValue = calculePourcentValuePourcentage(getGoodValueUrssaf(props.data.price_value, props.data.benefit_value), props.data.ursaf_value)
            break;
        case 3:
            name = "Commission"
            localValue = calculePourcentValuePourcentage(props.data.price_value, props.data.commission_value)
            break;
        case 4:
            name = "Bénéfice"
            localValue = props.data.benefit_value
            break;
        default:
            name = null
            value = null
    }

    return { name: name, value: localValue }
});

const pourcentCalcule = () => {
    const expensePourcent = calculeValuePourcentage(props.data.price_value, props.data.expense_value)
    const urssafPourcent = props.data.ursaf_value
    const commissionPourcent = props.data.commission_value
    const benefitPourcent = props.data.benefit_pourcent
    pourcent.value = [expensePourcent, urssafPourcent, commissionPourcent, benefitPourcent]
}

onMounted(() => {
    pourcentCalcule()
})


</script>
