<template>
    <DefaultBox customClass="xl:w-1/3 xl:h-[500px] w-full flex flex-col space-y-3">
        <h3 class="mt-2 font-bold tracking-tight text-gray-900 text-16">Votre abonnement</h3>

        <h3 v-if="typeSubscription == 2 || typeSubscription == 4 || typeSubscription == 5"
            class="mt-2 font-bold tracking-tight text-gray-900 text-14">Abonnement : Unlimited</h3>
        <h3 v-else-if="typeSubscription == 0" class="mt-2 font-bold tracking-tight text-gray-900 text-14">Abonnement :
            Starter</h3>
        <h3 v-else-if="typeSubscription == 1 || typeSubscription == 3"
            class="mt-2 font-bold tracking-tight text-gray-900 text-14">Abonnement :
            Pro</h3>

        <p class="mt-2 tracking-tight text-gray-700 text-14">L'application est gratuit pendant l'ALFA</p>
    </DefaultBox>
</template>


<script setup>
import { ref } from 'vue';
import DefaultBox from '@/components/Layout/DefaultBox.vue'

import authService from '@/api/services/authService';

const user = authService.getUser();

const typeSubscription = ref(user['type_subscription'])

</script>
