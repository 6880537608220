<template>
    <ProductListModal :open="open" :setOpen="setOpen" :name="productData?.name" :data="classementAll" />
    <DefaultAppLayout>
        <div class="xl:mr-3 mr-0" v-if="!isLoading">
            <header class="flex xl:flex-row flex-col space-y-3 xl:items-center justify-between">
                <div class="flex items-center justify-between flex-wrap">
                    <h1 class="font-semibold text-24 text-slate-800">{{ productData?.name }}</h1>
                    <div class="flex space-x-2 xl:invisible visible">
                        <button type="button" @click="displayDateBtn = !displayDateBtn"
                            class="rounded-full bg-slate-900 p-3 text-c_light_gray shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-700">
                            <CalendarIcon class="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
                <div v-if="displayDateBtn" class="flex items-center space-x-5">
                    <DataSwitcher :months="months" :years="years" :productData="productData" v-model:isYears="isYears"
                        v-model:monthSelected="monthSelected" v-model:yearSelected="yearSelected" />
                </div>
            </header>
            <main class="flex flex-col mt-3 space-y-3">
                <div class="flex xl:flex-row flex-col space-y-3 xl:space-y-0 xl:space-x-3">
                    <div class="xl:w-1/3 w-full">
                        <NumberCard v-if="benefitMonth" :data="benefitMonth" title="Votre chiffre d'affaire"
                            :subtitle="`en ${displayDate}`" :value="numberSapce(roundNumbers(benefitMonth.price_value))"
                            unity="€" :subvalue="`${roundNumbers(benefitMonth.nb_product)}`"
                            :subtitlevalue="`ventes en ${displayDate}`" changeType="increase" change="0" />
                        <NoDataCard v-else />
                    </div>
                    <DefaultBox customClass="xl:w-1/3 w-full h-[300px] flex flex-col justify-between">
                        <HeaderArrowNext title="Vos 4 products canaux de ventes" subtitle="" isArrow="false"
                        :onClick="() => setOpen(true)" :icon="UsersIcon" />
                        <TopProductTable v-if="classement && classement.length > 0" :data="classement" />
                        <NoDataCard v-else />
                    </DefaultBox>
                    <DefaultBox customClass="xl:w-2/3 w-full h-[300px] flex flex-col justify-between">
                        <HeaderArrowNext title="Vos 4 meilleurs canaux de ventes" subtitle="" to="/"
                            :icon="UsersIcon" />
                        <SourceTable v-if="canalMonth && canalMonth.length > 0" :canalMonth="canalMonth" />
                        <NoDataCard v-else />
                    </DefaultBox>
                </div>
                <div class="flex xl:flex-row flex-col space-y-3 xl:space-y-0 xl:space-x-3">
                    <div class="xl:w-1/4 w-full">
                        <NumberCard v-if="benefitMonth" :data="benefitMonth" title="Votre bénéfice moyen"
                            :subtitle="`en ${displayDate}`" :value="roundNumbers(benefitMonth.benefit_pourcent)"
                            unity="%" :subvalue="`${roundNumbers(benefitMonth.benefit_value)} €`"
                            :subtitlevalue="`en ${displayDate}`" changeType="increase" change="0" />
                        <NoDataCard v-else />
                    </div>
                    <div class="xl:w-1/4 w-full">
                        <NumberCard v-if="benefitMonth" :data="benefitMonth" title="Votre Urssaf moyen"
                            :subtitle="`en ${displayDate}`"
                            :value="roundNumbers(getGoodValueUrssaf(benefitMonth.price_value, benefitMonth.benefit_value) * (benefitMonth.ursaf_value / 100))"
                            unity="€" :subvalue="`${roundNumbers(benefitMonth.ursaf_value)} %`"
                            :subtitlevalue="`en ${displayDate}`" changeType="increase" change="0" />
                        <NoDataCard v-else />
                    </div>
                    <div class="xl:w-1/4 w-full">
                        <NumberCard v-if="benefitMonth" :data="benefitMonth" title="Votre commission moyen"
                            :subtitle="`en ${displayDate}`"
                            :value="roundNumbers(benefitMonth.price_value * (benefitMonth.commission_value / 100))"
                            unity="€" :subvalue="`${roundNumbers(benefitMonth.commission_value)} %`"
                            :subtitlevalue="`en ${displayDate}`" changeType="increase" change="0" />
                        <NoDataCard v-else />
                    </div>
                    <div class="xl:w-1/4 w-full">
                        <NumberCard v-if="benefitMonth" :data="benefitMonth" title="Votre bénéfice horaire"
                            :subtitle="`en ${displayDate}`"
                            :value="roundNumbers((benefitMonth.benefit_value / benefitMonth.time_value))" unity="€/h"
                            :subvalue="`${roundNumbers(benefitMonth.time_value)} h`"
                            :subtitlevalue="`en ${displayDate}`" changeType="increase" change="0" />
                        <NoDataCard v-else />
                    </div>
                </div>
                <DefaultBox customClass="w-full">
                    <div class="flex flex-col justify-between h-full mb-3 space-x-2">
                        <HeaderArrowNext title="Évolution de vos revenus sur ce produit"
                            subtitle="sur les 5 derniers mois" :to="'/'" :icon="PresentationChartLineIcon" />
                        <div class="h-[500px] mt-3">
                            <LineStyledChart :chartData="chartData" />
                        </div>
                    </div>
                </DefaultBox>
            </main>
        </div>
        <DefaultLoading v-else />
    </DefaultAppLayout>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { toast } from "vue-sonner";
import { useRoute } from 'vue-router';

const route = useRoute();

import { roundNumbers } from '@/utils/RoundNumbers';
import { monthLetter } from '@/utils/DataFormater';
import { getGoodValueUrssaf } from '@/utils/GetUrssafValue';

import DataSwitcher from '@/components/Ui/DataSwitcher.vue'
import NumberCard from '@/components/Ui/NumberCard.vue';
import NoDataCard from '@/components/Other/NoDataCard';
import DefaultAppLayout from '../../../components/Layout/DefaultAppLayout.vue';
import DefaultBox from '../../../components/Layout/DefaultBox.vue';
import HeaderArrowNext from '../../../components/Header/HeaderArrowNext.vue';
import SourceTable from '../../../components/Table/Other/SourceTable.vue';
import TopProductTable from '../../../components/Table/Other/TopProductTable.vue';
import LineStyledChart from '../../../components/Chart/LineStyledChart.vue';
import DefaultLoading from '@/components/Ui/DefaultLoading.vue';
import ProductListModal from '@/components/Modal/ProductListModal.vue';

import { GetAllViewBenefitMonthClient, GetAllViewBenefitYearClient, GetAllViewCanalMonthCategory, GetBestProductSalesMonthClient, GetAllViewCanalYearCategory, GetBestProductSalesYearClient } from '@/api/services/StatViewService';
import { GetClientById } from '@/api/services/ClientServiceFunctions';
import { months, years } from '@/utils/DateSelectData';
import {
    UsersIcon,
    PresentationChartLineIcon,
    CalendarIcon
} from '@heroicons/vue/20/solid'
import { numberSapce } from '@/utils/DataFormater';

import { useScreenStore } from '@/stores/useScreenStore'

const screenStore = useScreenStore();

const monthSelected = ref(months[0])
const yearSelected = ref(years[0])
const classement = ref(null);
const classementAll = ref(null);
const isYears = ref(false)

const benefitMonth = ref(null);
const canalMonth = ref(null);
const productData = ref(null);
const productArchived = ref("Archiver");
const chartData = ref(null);
const isLoading = ref(true);
const displayDate = ref(null);
const displayDateBtn = ref(false);
const open = ref(false)

const setOpen = (value) => {
    open.value = value;
}

screenStore.initializeScreenSizeListener();

const isLargeScreen = screenStore.isLargeScreen;

const getData = async (nowMonthChoise, nowYearsChoise) => {
    try {
        const date = new Date();
        let nowMonth = date.getMonth() + 1;
        let nowYears = date.getFullYear();
        const clientId = route.params.id;

        if (nowMonthChoise && nowYearsChoise) {
            nowMonth = nowMonthChoise;
            nowYears = nowYearsChoise;
        }

        nowMonth = nowMonth < 10 ? "0" + nowMonth.toString() : nowMonth.toString();

        const fetchProductData = async () => {
            if (!isYears.value) {
                return Promise.all([
                    GetClientById(clientId),
                    GetAllViewCanalMonthCategory(`category_id=${clientId}&month=${nowMonth}&years=${nowYears}&order%5Bprice_value%5D=desc&itemsPerPage=4&page=1`),
                    GetAllViewBenefitMonthClient(`client_id=${clientId}&month=${nowMonth}&years=${nowYears}&order%5Bdate_full%5D=desc`),
                    GetAllViewBenefitMonthClient(`client_id=${clientId}&years=${nowYears}&order%5Bdate_full%5D=desc`),
                    GetBestProductSalesMonthClient(`client_id=${clientId}&month=${nowMonth}&years=${nowYears}&order%classement%5D=desc`)
                ]);
            } else {
                return Promise.all([
                    GetClientById(clientId),
                    GetAllViewCanalYearCategory(`category_id=${clientId}&years=${nowYears}`),
                    GetAllViewBenefitYearClient(`client_id=${clientId}&years=${nowYears}`),
                    GetAllViewBenefitYearClient(`client_id=${clientId}&years=${nowYears}&order%5Bdate_full%5D=desc`),
                    GetBestProductSalesYearClient(`client_id=${clientId}&years=${nowYears}&order%classement%5D=desc`)
                ]);
            }
        };

        const [category, canal, benefitData, benefitDatas, classementData] = await fetchProductData();

        productArchived.value = category.isArchived ? "Désarchiver" : "Archiver"
        productData.value = category;
        canalMonth.value = canal;
        classementAll.value = classementData;
        classement.value = classementData.length >= 10 ? classementData.slice(0, 4) : classementData;
        benefitMonth.value = benefitData[0];
        chartData.value = createChartData(benefitDatas);
        displayDate.value = !isYears.value ? monthLetter(benefitData[0]?.month) : !isYears.value ? benefitData[0]?.years : null
    } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue");
    } finally {
        isLoading.value = false;
    }
};

const createChartData = (benefitDatas) => {
    return {
        labels: benefitDatas.map((item) => monthLetter(item.month)),
        datasets: [
            {
                label: "Chiffre d'affaire",
                data: benefitDatas.map((item) => roundNumbers(item.price_value)),
                backgroundColor: "#E1BEF6",
                borderColor: "#E1BEF6",
                borderWidth: 2,
                fill: false,
                tension: 0.4
            },
            {
                label: "Bénéfice",
                data: benefitDatas.map((item) => roundNumbers(item.benefit_value)),
                backgroundColor: "#E1FF72",
                borderColor: "#E1FF72",
                borderWidth: 2,
                fill: false,
                tension: 0.4
            },
        ],
    }
};

const setDefaultData = () => {
    const date = new Date();
    const nowMonth = date.getMonth() + 1;
    const nowYears = date.getFullYear();

    const month = months.filter((item) => item.id === nowMonth)
    const year = years.filter((item) => item.name === nowYears)

    monthSelected.value = month[0]
    yearSelected.value = year[0]
}

watch([monthSelected, yearSelected, isYears], ([newMonth, newYear]) => {
    getData(newMonth.id, newYear.name);
});

onMounted(() => {
    setDefaultData()
    getData();
    displayDateBtn.value = isLargeScreen ? true : false;
});
</script>
