// ProductServiceFunctions.js
import axios from "@/api/config";
import { toast } from "vue-sonner";
import { GetCallApi, GetCallSingleApi } from "@/api/services/utilsApi";

// Récupérer tous les produits
export const GetAllProductData = async () => {
  return await GetCallApi("/products?page=1&itemsPerPage=1000");
};

// Récupérer un produit par ID
export const GetProductById = async (id) => {
  return await GetCallSingleApi(`/products/${id}`);
};

// Créer un nouveau produit
export const CreateProduct = async (productData) => {
  try {
    const response = await axios.post("/products", productData);
    toast.success("Produit créé avec succès");
    return response;
  } catch (error) {
    console.error(error);
    toast.error("Une erreur est survenue");
  }
};

// Mettre à jour un produit
export const UpdateProduct = async (id, productData) => {
  try {
    const response = await axios.put(`/products/${id}`, productData);
    toast.success("Produit modifier avec succes !");
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("Une erreur c'est produite");
  }
};

export const PatchProduct = async (id, productData) => {
  try {
    const response = await axios.patch(`/products/${id}`, productData);
    toast.success("Produit modifier avec succes !");
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("Une erreur c'est produite");
  }
};

// Supprimer un produit
export const DeleteProduct = async (id) => {
  try {
    await axios.delete(`/products/${id}`);
    toast.success("Le produit a été supprimé avec succès !");
  } catch (error) {
    console.error(error);
    toast.error("Une erreur est survenue");
  }
};
