<template>
    <div id="conactTesteur" class="relative isolate mt-24">
        <div class="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
            <div class="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
                <div class="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                    <h2 class="text-3xl font-bold tracking-tight text-gray-900">Faire votre demande</h2>
                    <p class="mt-6 text-lg leading-8 text-gray-600">
                        Rejoignez notre programme de testeurs et économisez 35 % sur votre abonnement !
                    </p>
                </div>
            </div>
            <form @submit.prevent="submitForm" class="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
                <div class="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                    <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div>
                            <label for="first-name" class="block text-sm font-semibold leading-6 text-gray-900">
                                Prénom
                            </label>
                            <div class="mt-2.5">
                                <input type="text" v-model="form.firstName" id="first-name"
                                    class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                    required />
                            </div>
                        </div>
                        <div>
                            <label for="last-name" class="block text-sm font-semibold leading-6 text-gray-900">
                                Nom
                            </label>
                            <div class="mt-2.5">
                                <input type="text" v-model="form.lastName" id="last-name"
                                    class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                    required />
                            </div>
                        </div>
                        <div>
                            <label for="type-activity" class="block text-sm font-semibold leading-6 text-gray-900">
                                Type d'activités
                            </label>
                            <div class="mt-2.5">
                                <input type="text" v-model="form.typeActivity" id="type-activity"
                                    class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                    required />
                            </div>
                        </div>
                        <div>
                            <label for="size" class="block text-sm font-semibold leading-6 text-gray-900">
                                Taille de l'entreprise / CA annuel
                            </label>
                            <div class="mt-2.5">
                                <select id="size" name="size" v-model="form.size" required
                                    class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-purple-600 sm:text-sm sm:leading-6">
                                    <option value="0-10000">0€ - 10 000€</option>
                                    <option value="10001-50000">10 001€ - 50 000€</option>
                                    <option value="50001-200000">50 001€ - 200 000€</option>
                                    <option value="200001-500000">200 001€ - 500 000€</option>
                                    <option value="500001-1000000">500 001€ - 1 000 000€</option>
                                    <option value="1000001-5000000">1 000 001€ - 5 000 000€</option>
                                    <option value="5000001-10000000">5 000 001€ - 10 000 000€</option>
                                    <option value="10000001-more">Plus de 10 000 000€</option>
                                </select>
                            </div>
                        </div>
                        <div class="sm:col-span-2">
                            <label for="email" class="block text-sm font-semibold leading-6 text-gray-900">
                                Email
                            </label>
                            <div class="mt-2.5">
                                <input type="email" v-model="form.email" id="email"
                                    class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                    required />
                            </div>
                        </div>
                        <div class="sm:col-span-2">
                            <label for="message" class="block text-sm font-semibold leading-6 text-gray-900">
                                Message
                            </label>
                            <div class="mt-2.5">
                                <textarea v-model="form.message" id="message" rows="4"
                                    class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                    required></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="mt-8 flex justify-end">
                        <button type="submit"
                            class="rounded-md bg-purple-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">
                            Envoyer votre demande
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from "@/api/config";
import { toast } from "vue-sonner";

// Définition des données du formulaire
const form = ref({
    firstName: '',
    lastName: '',
    typeActivity: '',
    size: '',
    email: '',
    message: ''
});

// Fonction pour soumettre le formulaire
const submitForm = async () => {
    // Validation basique avant l'envoi
    if (!form.value.firstName || !form.value.lastName || !form.value.typeActivity || !form.value.size || !form.value.email || !form.value.message) {
        toast.error('Veuillez remplir tous les champs obligatoires.');
        return;
    }

    try {
        // Envoyer les données via l'API
        await axios.post("/contact-testeur", {
            prenom: form.value.firstName,
            nom: form.value.lastName,
            typeActivite: form.value.typeActivity,
            tailleEntreprise: form.value.size,
            email: form.value.email,
            message: form.value.message
        });

        // Réinitialiser le formulaire après l'envoi
        form.value.firstName = '';
        form.value.lastName = '';
        form.value.typeActivity = '';
        form.value.size = '';
        form.value.email = '';
        form.value.message = '';

        toast.success("Votre demande a été envoyée avec succès !");
    } catch (error) {
        toast.error("Une erreur est survenue lors de l'envoi du formulaire.");
    }
};
</script>
