<template>
    <DefaultBox customClass="xl:w-1/3 xl:h-[500px] w-full flex flex-col space-y-3">
        <h3 class="mt-2 font-bold tracking-tight text-gray-900 text-16">Information personnel</h3>
        <p class="text-gray-900 text-14">Adresse email : <span class="font-semibold">{{ user.username
                }}</span></p>
        <div class="flex flex-col space-y-3">
            <p class="mt-5 font-semibold text-gray-900 text-14">MOT DE PASSE</p>
            <p class="text-gray-700 text-12">Modifiez le mot de passe que vous utilisez
                actuellement pour accéder à votre compte dans Maker Copilot.
            </p>
            <form @submit.prevent="resetPassword" class="space-y-6">
                <div>
                    <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Ancien mot
                        de passe</label>
                    <div class="mt-2">
                        <input type="password" name="old_password" id="old_password" v-model="password"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div>
                    <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Nouveau mot
                        de passe</label>
                    <div class="mt-2">
                        <input type="password" name="new_password" id="new_password" v-model="confirmPassword"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <DefaultSubmitBtn>Modifier le mot de passe</DefaultSubmitBtn>
            </form>
        </div>
    </DefaultBox>
</template>


<script setup>
import { ref } from 'vue';
import { toast } from "vue-sonner";
import DefaultBox from '@/components/Layout/DefaultBox.vue'
import authService from "@/api/services/authService";
import DefaultSubmitBtn from "@/components/Button/DefaultSubmitBtn.vue"

import { ResetPassword } from '@/api/services/UserServiceFunctions';

const user = authService.getUser();

const password = ref(null)
const confirmPassword = ref(null)

const resetPassword = () => {
    if (password.value === confirmPassword.value) {
        ResetPassword(password.value)
    } else {
        toast.error("Les mots de passe ne sont pas identiques");
    }
}
</script>
