<script setup>
import { ref, onMounted } from 'vue'
import { Toaster } from 'vue-sonner'
import { RouterView } from 'vue-router'
import { register } from 'register-service-worker'

const showInstallPrompt = ref(false)
const isAndroid = ref(false)
const isIos = ref(false)
const isStandalone = ref(false)
const updateAvailable = ref(false)
const registration = ref(null)

onMounted(() => {
  console.log('Current environment:', process.env.NODE_ENV)

  const userAgent = window.navigator.userAgent.toLowerCase()
  isAndroid.value = userAgent.includes('android')
  isIos.value = /iphone|ipad|ipod/.test(userAgent)

  // Détection du mode standalone
  if (window.matchMedia('(display-mode: standalone)').matches || navigator.standalone) {
    isStandalone.value = true
  }

  if (!isStandalone.value && process.env.NODE_ENV === "production") {
    // Afficher la modale d'installation si l'application n'est pas en standalone
    setTimeout(() => {
      showInstallPrompt.value = true
    }, 3000)
  }

  // Enregistrer le service worker et gérer les mises à jour
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('App is being served from cache by a service worker.')
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(reg) {
      console.log('New content is available; please refresh.')
      updateAvailable.value = true
      registration.value = reg
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
})

const refreshApp = () => {
  if (registration.value && registration.value.waiting) {
    registration.value.waiting.postMessage({ type: 'SKIP_WAITING' })
    window.location.reload()
  }
}
</script>

<template>
  <div class="bg-c_light_gray">
    <Toaster position="top-right" />
    <RouterView />

    <!-- Notification de mise à jour disponible -->
    <div v-if="updateAvailable"
      class="fixed bottom-4 right-4 bg-c_gray text-slate-900 p-4 rounded-lg shadow-lg animate-fade-in">
      <p>Une nouvelle version est disponible !</p>
      <button @click="refreshApp" class="mt-2 bg-slate-900 text-c_gray font-bold py-1 px-3 rounded">
        Mettre à jour
      </button>
    </div>

    <!-- Modale pour Android -->
    <div v-if="showInstallPrompt && isAndroid"
      class="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50 animate-fade-in">
      <div class="bg-white p-6 rounded-xl shadow-2xl transform transition-all duration-300 ease-in-out max-w-sm mx-4">
        <h2 class="text-2xl font-semibold text-center text-gray-800">Installer l'application</h2>
        <p class="mt-3 text-gray-600 text-center">Appuyez sur les trois points en haut à droite de votre navigateur,
          puis sélectionnez "Ajouter à l'écran d'accueil".</p>
        <div class="mt-5 flex justify-center">
          <button @click="showInstallPrompt = false"
            class="bg-slate-900 text-white font-bold py-2 px-6 rounded-full shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform">Fermer</button>
        </div>
      </div>
    </div>

    <!-- Modale pour iOS -->
    <div v-if="showInstallPrompt && isIos"
      class="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50 animate-fade-in">
      <div class="bg-white p-6 rounded-xl shadow-2xl transform transition-all duration-300 ease-in-out max-w-sm mx-4">
        <h2 class="text-2xl font-semibold text-center text-gray-800">Installer l'application</h2>
        <p class="mt-3 text-gray-600 text-center">Appuyez sur l'icône de partage en bas de votre navigateur, puis
          sélectionnez "Ajouter à l'écran d'accueil".</p>
        <div class="mt-5 flex justify-center">
          <button @click="showInstallPrompt = false"
            class="bg-slate-900 text-white font-bold py-2 px-6 rounded-full shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform">Fermer</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 0.5s ease-in-out;
}
</style>
