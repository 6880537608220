<template>
    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-50" @close="open = false">
            <SubModal />
        </Dialog>
    </TransitionRoot>
    <DefaultAppLayout>
        <div class="p-5">
            <div class="flex xl:flex-row flex-col xl:items-center items-start justify-between">
                <h2 class="font-bold tracking-tight text-gray-900 text-22 xl:mb-0 mb-5">Tous vos produits</h2>
                <div class="flex xl:flex-row flex-col xl:items-center items-start xl:space-x-3 space-y-3">
                    <SwitchGroup as="div" class="flex items-center">
                        <Switch v-model="isDisplayArchived"
                            :class="[isDisplayArchived ? 'bg-c_purple' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-c_purple focus:ring-offset-2']">
                            <span aria-hidden="true"
                                :class="[isDisplayArchived ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                        </Switch>
                        <SwitchLabel as="span" class="ml-3 text-sm">
                            <span class="font-medium text-gray-900">Afficher les produits archivé</span>
                        </SwitchLabel>
                    </SwitchGroup>
                    <div v-if="products">
                        <DefaultLinkBtn v-if="isCreateProduct" to="/create-product" title="Créer un produit" />
                        <button v-else @click="open = true"
                            class="rounded-md px-3 py-2 text-sm font-semibold bg-c_light_gray hover:bg-c_gray border-slate-800 border text-slate-800">
                            Créer un produit</button>
                    </div>
                </div>
            </div>
            <div v-if="products != null" class="grid grid-cols-1 mt-5 gap-x-3 gap-y-8 lg:grid-cols-4 xl:gap-x-4">
                <DefaultProductCard v-for="product in products" :key="product.id" :product="product"
                    :isDisplayArchived="isDisplayArchived" />
            </div>
            <div v-else class="grid grid-cols-1 mt-5 gap-x-3 gap-y-8 lg:grid-cols-4 xl:gap-x-4">
                <SkeletonLine customClass="w-full h-36" />
                <SkeletonLine customClass="w-full h-36" />
                <SkeletonLine customClass="w-full h-36" />
                <SkeletonLine customClass="w-full h-36" />
                <SkeletonLine customClass="w-full h-36" />
            </div>

        </div>
    </DefaultAppLayout>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Switch, SwitchGroup, SwitchLabel, TransitionRoot, Dialog } from '@headlessui/vue'

import authService from '@/api/services/authService';

const user = authService.getUser();

import DefaultAppLayout from '@/components/Layout/DefaultAppLayout.vue'
import DefaultLinkBtn from '@/components/Button/DefaultLinkBtn.vue'
import DefaultProductCard from '@/components/Other/DefaultProductCard.vue';
import { GetAllProductData } from "@/api/services/ProductServiceFunctions";
import SkeletonLine from '@/components/Ui/SkeletonLine.vue'
import SubModal from '@/components/Modal/SubModal.vue';

const products = ref(null);
const isCreateProduct = ref(false);
const isDisplayArchived = ref(false)
const open = ref(false)

const fetchProducts = async () => {
    const data = await GetAllProductData();
    if (data) {
        subscriptionValidation(data)
        products.value = data.map(product => ({
            ...product,
            // imageSrc: product.imageSrc || 'https://redthread.uoregon.edu/files/original/affd16fd5264cab9197da4cd1a996f820e601ee4.png', // Remplacez par l'URL par défaut de l'image si nécessaire
            // imageAlt: product.imageAlt || 'Product image',
            href: `/product/${product.id}`, // Lien vers la page produit détaillée
        }));
    }
};

const subscriptionValidation = (data) => {
    const typeSubscription = user['type_subscription'];

    if (typeSubscription == 2 || typeSubscription == 4 || typeSubscription == 5) {
        isCreateProduct.value = true
    } else if (data.length >= 5 && typeSubscription == 0) {
        isCreateProduct.value = false
    } else if (data.length >= 10 && (typeSubscription == 1 || typeSubscription == 3)) {
        isCreateProduct.value = false
    } else {
        isCreateProduct.value = true
    }

}

onMounted(() => {
    fetchProducts();
});
</script>
