export const getGoodValueUrssaf = (price, benefit) => {
  const typeOfUrssaf = localStorage.getItem("urssafType");
  let result = price;
  if (typeOfUrssaf == 1) {
    result = benefit;
  } else {
    result = price;
  }

  return result;
};
