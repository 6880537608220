export const roundNumbers = (value, decimals = 1) => {
  if (value === undefined || value === null) {
    return null;
  }

  value = Number(value);
  if (isNaN(value)) {
    return value; // Si ce n'est pas un nombre, retournez la valeur telle quelle
  }

  if (decimals === 0) {
    return Math.round(value);
  }

  const multiplier = 10 ** decimals;
  return Math.round(value * multiplier) / multiplier;
};
