// UserServiceFunctions.js
import axios from "@/api/config";
import { toast } from "vue-sonner";
import { GetCallApi, GetCallSingleApi } from "@/api/services/utilsApi";

// Récupérer tous les produits
export const GetAllUserData = async () => {
  return await GetCallApi("/users");
};

// Récupérer un produit par ID
export const GetUserById = async (id) => {
  return await GetCallSingleApi(`/users/${id}`);
};

// Créer un nouveau produit
export const CreateUser = async (productData) => {
  try {
    const response = await axios.post("/users", productData);
    toast.success("User created successfully");
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("Failed to create product");
  }
};

// Mettre à jour un produit
export const UpdateUser = async (id, productData) => {
  process.env.NODE_ENV !== "production" &&
    console.log("productData", productData);
  try {
    const response = await axios.patch(`/users/${id}`, productData);
    toast.success("Modification effectuée avec success !");
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("Une erreur est survenue");
  }
};

// Supprimer un produit
export const DeleteUser = async (id) => {
  try {
    await axios.delete(`/users/${id}`);
    toast.success("User deleted successfully");
  } catch (error) {
    console.error(error);
    toast.error("Failed to delete product");
  }
};

// Forgot password
export const ForgotPassword = async (email) => {
  try {
    const response = await axios.post("/forgot-password", { email: email });
    toast.success("Email envoyé avec succès !");
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
  }
};

// Reset password
export const ResetPassword = async (password) => {
  try {
    const response = await axios.post("/reset-password", {
      password: password,
    });
    toast.success("Mot de passe modifié avec succès !");
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
  }
};
