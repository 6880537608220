<template>
    <Combobox as="div" v-model="selectedData" @update:modelValue="clearQuery" multiple>
        <ComboboxLabel class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</ComboboxLabel>
        <div class="relative mt-2">
            <ComboboxInput
                class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                @input="handleInput($event.target.value)" @blur="clearQuery" :display-value="displaySelectedItems" />
            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                <ChevronUpDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>

            <ComboboxOptions v-if="filteredData.length > 0"
                class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <ComboboxOption v-for="person in filteredData" :key="person.id" :value="person" as="template"
                    v-slot="{ active, selected }">
                    <li
                        :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-purple-600 text-white' : 'text-gray-900']">
                        <span :class="['block truncate', selected ? 'font-semibold' : '']">
                            {{ person.name }}
                        </span>
                        <span v-if="selected"
                            :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-purple-600']">
                            <CheckIcon class="w-5 h-5" aria-hidden="true" />
                        </span>
                    </li>
                </ComboboxOption>
            </ComboboxOptions>
        </div>
    </Combobox>
</template>


<script setup>
import { computed, ref, watch } from 'vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from '@headlessui/vue';
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
    label: String,
    data: Array,
    selectedData: Array,
    query: String
});

const emit = defineEmits(['update:selectedData']);

const query = ref(props.query || '');
const selectedData = ref(props.selectedData || []);

const filteredData = computed(() =>
    query.value === ''
        ? props.data
        : props.data.filter((person) =>
            person.name.toLowerCase().includes(query.value.toLowerCase())
        )
);

function handleInput(value) {
    query.value = value;
}

function clearQuery() {
    query.value = '';
}

function displaySelectedItems() {
    return selectedData.value.map(item => item.name).join(', ');
}

// Synchronize prop with local ref
watch(() => props.selectedData, (newValue) => {
    selectedData.value = newValue;
});

// Emit event when selectedData changes
watch(selectedData, (newValue) => {
    emit('update:selectedData', newValue);
}, { deep: true });
</script>