// api/services/authService.js
import apiClient from "@/api/config";
import { jwtDecode } from "jwt-decode";
import router from "@/router";

const authService = {
  login: async (credentials) => {
    const response = await apiClient.post("/login", credentials);
    const { token, refresh_token } = response.data;

    const decoded = token ? jwtDecode(token) : null;

    localStorage.setItem("token", token);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("urssafType", decoded.urssaf_type);
    localStorage.setItem("urssafPourcent", decoded.urssaf_pourcent);
    localStorage.setItem("abatementPourcent", decoded.abatement_pourcent);
    localStorage.setItem("objectifValue", decoded.objectif_value);
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return jwtDecode(token);
  },

  refreshToken: async () => {
    console.log("refreshToken");
    const refresh_token = localStorage.getItem("refresh_token");
    if (!refresh_token) throw new Error("No refresh token available");

    const response = await apiClient.post("/token/refresh", {
      refresh_token: refresh_token,
    });

    const { token } = response.data;
    localStorage.setItem("token", token);
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return token;
  },

  logout: () => {
    console.log("logout");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("urssafType");
    localStorage.removeItem("urssafPourcent");
    localStorage.removeItem("abatementPourcent");
    delete apiClient.defaults.headers.common["Authorization"];
    router.push({ path: "/" });
  },

  getToken: () => {
    console.log("getToken");
    return localStorage.getItem("token");
  },

  getUser: () => {
    const token = localStorage.getItem("token") || null;
    const decoded = token ? jwtDecode(token) : null;
    return decoded;
  },
};

export default authService;
