<template>
    <div class="relative isolate mt-24">
        <div class="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
            <div class="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
                <div class="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                    <h2 class="text-3xl font-bold tracking-tight text-gray-900">Contactez-nous</h2>
                    <p class="mt-6 text-lg leading-8 text-gray-600">Vous avez des questions ou besoin d'assistance ?
                        Remplissez le formulaire ci-dessous et nous vous répondrons rapidement.</p>
                </div>
            </div>
            <form @submit.prevent="submitForm" class="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
                <div class="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                    <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div>
                            <label for="first-name"
                                class="block text-sm font-semibold leading-6 text-gray-900">Prénom</label>
                            <div class="mt-2.5">
                                <input type="text" v-model="form.firstName" id="first-name"
                                    class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                    required />
                            </div>
                        </div>
                        <div>
                            <label for="last-name"
                                class="block text-sm font-semibold leading-6 text-gray-900">Nom</label>
                            <div class="mt-2.5">
                                <input type="text" v-model="form.lastName" id="last-name"
                                    class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                    required />
                            </div>
                        </div>
                        <div class="sm:col-span-2">
                            <label for="email" class="block text-sm font-semibold leading-6 text-gray-900">Email</label>
                            <div class="mt-2.5">
                                <input type="email" v-model="form.email" id="email"
                                    class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                    required />
                            </div>
                        </div>
                        <div class="sm:col-span-2">
                            <label for="message"
                                class="block text-sm font-semibold leading-6 text-gray-900">Message</label>
                            <div class="mt-2.5">
                                <textarea v-model="form.message" id="message" rows="4"
                                    class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                    required></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="mt-8 flex justify-end">
                        <button type="submit"
                            class="rounded-md bg-purple-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">Envoyer
                            le message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from "@/api/config";
import { toast } from "vue-sonner";

// Définition des données du formulaire
const form = ref({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
});

// Fonction pour soumettre le formulaire
const submitForm = async () => {
    // Validation basique avant l'envoi
    if (!form.value.firstName || !form.value.lastName || !form.value.email || !form.value.message) {
        alert('Veuillez remplir tous les champs obligatoires.');
        return;
    }

    // Simulation de l'envoi du formulaire (à remplacer par une requête réelle)
    try {
        // Exécuter la logique d'envoi (API, serveur, etc.)
        await axios.post("/contact-email", {
            prenom: form.value.firstName,
            nom: form.value.lastName,
            email: form.value.email,
            message: form.value.message
        });

        // Réinitialiser le formulaire après l'envoi
        form.value.firstName = '';
        form.value.lastName = '';
        form.value.email = '';
        form.value.message = '';

        toast.success("Produit créé avec succès");
    } catch (error) {
        toast.error("Une erreur est survenue");
    }
};
</script>