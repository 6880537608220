import { defineStore } from 'pinia';
import { ref, onMounted, onUnmounted } from 'vue';

export const useScreenStore = defineStore('screen', () => {
  const isLargeScreen = ref(window.screen.width > 1280);

  const updateScreenSize = () => {
    isLargeScreen.value = window.screen.width > 1280;
  };

  const initializeScreenSizeListener = () => {
    onMounted(() => {
      window.addEventListener('resize', updateScreenSize);
      updateScreenSize(); // Initial check
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateScreenSize);
    });
  };

  return {
    isLargeScreen,
    initializeScreenSizeListener
  };
});
