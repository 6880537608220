<template>
    <DefaultBox customClass="mt-6 overflow-hidden border-t border-gray-100">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th scope="col"
                                class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-12 font-semibold text-gray-900 sm:pl-0">
                                Nom</th>
                            <th scope="col"
                                class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-12 font-semibold text-gray-900 sm:pl-0">
                                Date</th>
                            <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0">
                                <span class="sr-only">Voir</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="item in datas" :key="item.id">
                            <td class="py-2 pl-4 pr-3 font-semibold text-gray-500 whitespace-nowrap text-12 sm:pl-0">
                                {{ item.name }}</td>
                            <td class="py-2 pl-4 pr-3 text-gray-500 whitespace-nowrap text-12 sm:pl-0">
                                {{ new Date(item.createdAt).toLocaleDateString("fr-FR") }}</td>
                            <td
                                class="relative py-2 pl-3 pr-4 font-medium text-right whitespace-nowrap text-12 sm:pr-0">
                                <RouterLink :to="urlValue(item.id)" class="text-purple-600 hover:text-purple-900">Voir<span
                                        class="sr-only">, {{
                                            item.id }}</span></RouterLink>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </DefaultBox>
</template>


<script setup>
import { defineProps } from 'vue';
import { RouterLink } from 'vue-router';


import DefaultBox from '@/components/Layout/DefaultBox.vue';

const props = defineProps({
    datas: Array,
    type: Number
})

const urlValue = ((id) => {
    let url = null;

    switch (props.type) {
        case 1:
            url = "category/" + id;
            break;
        case 2:
            url = "canal/" + id;
            break;
        case 3:
            url = "client/" + id;
            break;
        default:
            url = "add"
            break;
    }

    return url;
});
</script>