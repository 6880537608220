<template>
    <thead class="border-b border-gray-300 text-gray-900">
        <tr>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-12 font-semibold text-gray-900 sm:pl-0">
                Nom</th>
            <th scope="col" class="hidden px-3 py-3.5 text-right text-12 font-semibold text-gray-900 sm:table-cell">
                Client
            </th>
            <th scope="col" class="hidden px-3 py-3.5 text-right text-12 font-semibold text-gray-900 sm:table-cell">Prix
            </th>
            <th scope="col" class="py-3.5 pl-3 pr-4 text-right text-12 font-semibold text-gray-900 sm:pr-0">
                Bénéfice</th>
        </tr>
    </thead>
</template>

<script setup>

</script>