<template>
    <DefaultAppLayout>
        <div v-if="typeSubscription == 1 || typeSubscription == 2 || typeSubscription == 3 || typeSubscription == 4 || typeSubscription == 5"
            class="px-6 py-24 sm:py-32 lg:px-8">
            <div class="mx-auto max-w-2xl text-center">
                <h2 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Fonctionnalité en développement</h2>
                <p class="mt-6 text-lg leading-8 text-gray-600">
                    Le rapport détaillé est actuellement en cours de développement pendant l'ALFAT. Si vous avez des questions ou des suggestions, n'hésitez pas à nous contacter à <a href="mailto:contact@maker-copilot.com" class="text-indigo-600 underline">contact@maker-copilot.com</a>.
                </p>
            </div>
        </div>
        <div v-else class="px-6 py-24 sm:py-32 lg:px-8">
            <div class="mx-auto max-w-2xl text-center">
                <h2 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                    Votre abonnement actuel ne permet pas d'accéder à cette fonctionnalité.
                </h2>
                <p class="mt-6 text-lg leading-8 text-gray-600">
                    Pour accéder à cette fonctionnalité, veuillez passer à un abonnement supérieur. Si vous avez besoin d'assistance, contactez-nous à <a href="mailto:contact@maker-copilot.com" class="text-indigo-600 underline">contact@maker-copilot.com</a>.
                </p>
                <RouterLink to="/settings"
                    class="mt-6 inline-flex w-full justify-center rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">
                    Mettre à niveau l'abonnement
                </RouterLink>
            </div>
        </div>
    </DefaultAppLayout>
</template>

<script setup>
import { ref } from 'vue';
import { RouterLink } from 'vue-router';
import authService from '@/api/services/authService';
import DefaultAppLayout from '@/components/Layout/DefaultAppLayout.vue'

const user = authService.getUser();
const typeSubscription = ref(user['type_subscription']);
</script>
