import { roundNumbers } from "@/utils/RoundNumbers";

export const calculeValuePourcentage = (totalValue, partialValue) => {
  if (totalValue === 0) return 0; // Evite la division par zéro
  return roundNumbers((partialValue / totalValue) * 100);
};

export const calculePourcentValuePourcentage = (totalValue, pourcentValue) => {
  if (totalValue === 0) return 0; // Evite la division par zéro
  return roundNumbers((pourcentValue * totalValue) / 100);
};

export const totalArray = (datas, value) => {
  return datas.reduce((total, item) => total + item[value], 0);
};
