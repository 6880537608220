<template>
    <Line v-if="chartData" :data="chartData" :options="chartOptions" />
</template>

<script setup>
import { defineProps } from "vue";
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip
} from "chart.js";
import { Line } from "vue-chartjs";

defineProps({
    chartData: Array
})

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Tooltip  // Register the Tooltip module
);

const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            display: false  // Hide the legend
        },
        tooltip: {
            enabled: true,  // Enable tooltips
            mode: 'index',  // Show tooltip at all points along the index
            intersect: false, // Show tooltip even if not hovering directly on the line
            callbacks: {
                label: function (context) {
                    let label = context.dataset.label || '';
                    if (label) {
                        label += ': ';
                    }
                    if (context.parsed.y !== null) {
                        label += context.parsed.y;
                    }
                    return label;
                }
            }
        }
    },
    scales: {
        x: {
            display: true,
            grid: {
                display: false
            },
            reverse: true
        },
        y: {
            display: true,
            grid: {
                display: false
            },
        },
    },
    elements: {
        line: {
            borderWidth: 2
        },
        point: {
            radius: 1
        }
    }
};
</script>
