// StatViewService.js
import axios from "@/api/config";
import { toast } from "vue-sonner";

export const GetCallApi = async (url) => {
  try {
    const response = await axios.get(url);
    process.env.NODE_ENV !== 'production' && console.log(url);
    process.env.NODE_ENV !== 'production' && console.log(response.data);
    return response.data["hydra:member"];
  } catch (error) {
    console.error(error);
    toast.error("Failed to fetch sales");
  }
};

export const GetCallSingleApi = async (url) => {
  try {
    const response = await axios.get(url);
    process.env.NODE_ENV !== 'production' && console.log(url);
    process.env.NODE_ENV !== 'production' && console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error("Failed to fetch sales");
  }
};