<template>
  <DefaultModalForm :open="open" :setOpen="setOpen" :action="forgotPassword" mainBtnTitle="Envoyer">
    <div class="mt-3 text-center">
      <DialogTitle as="h3" class="font-semibold leading-6 text-gray-900 text-24">
        Mot de passe oublier ?
      </DialogTitle>
      <div class="w-full py-7">
        <label for="email-address" class="block text-sm font-medium text-left text-gray-700">
          Votre adresse email
        </label>
        <div class="mt-2">
          <input type="email" name="email" autocomplete="off" v-model="valueInput"
            class="block w-full border-gray-300 rounded-md shadow-sm focus:border-purple-500 focus:ring-purple-500 text-10" />
        </div>
      </div>
    </div>
  </DefaultModalForm>
  <div class="flex flex-1 min-h-screen px-10 bg-c_light_gray">
    <div class="flex flex-col justify-center w-full xl:w-1/2">
      <div class="w-full max-w-sm mx-auto lg:w-96">
        <div>
          <img class="w-auto h-10" :src="logo" alt="Maker Copilot" />
          <h2 class="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">Vous connectez à Maker Copilot</h2>
        </div>

        <div class="mt-10">
          <div>
            <form @submit.prevent="login" class="space-y-6">
              <div>
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                <div class="mt-2">
                  <input id="email" v-model="email" type="email" placeholder="Email" required
                    class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-c_purple sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div>
                <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Mot de passe</label>
                <div class="mt-2">
                  <input id="password" v-model="password" type="password" placeholder="Mot de passe" required
                    class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-c_purple sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div class="flex items-center justify-between">
                <div class="text-sm leading-6">
                  <p @click="setOpen(true)" class="font-semibold cursor-pointer text-slate-900 hover:text-slate-800">Mot
                    de passe
                    oublier ?</p>
                </div>
              </div>

              <div>
                <button type="submit"
                  class="flex w-full justify-center rounded-md bg-slate-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600">Connexion</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLargeScreen" class="flex items-center justify-center w-1/2">
      <div class="relative isolate">
        <svg
          class="absolute inset-x-0 top-0 -z-10 h-[100vh] w-full stroke-gray-300 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
          aria-hidden="true">
          <defs>
            <pattern id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84" width="60" height="60" x="50%" y="-1"
              patternUnits="userSpaceOnUse">
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
            <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              stroke-width="0" />
          </svg>
          <rect width="100%" height="100%" stroke-width="0" fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
        </svg>
        <div
          class="absolute top-0 right-0 -ml-24 overflow-hidden left-1/2 -z-10 transform-gpu blur-3xl lg:ml-24 xl:ml-48"
          aria-hidden="true">
          <div class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#E1FF72] to-[#E1BEF6] opacity-90"
            style="clip-path: polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)" />
        </div>
        <img :src="mainImage" class="min-w-[20em]" />
      </div>
    </div>
  </div>
</template>




<script setup>
import { ref } from 'vue';
import authService from '@/api/services/authService'
import router from '@/router';
import logo from "@/assets/logo-black.svg"
import mainImage from "@/assets/main-image.webp"
import { toast } from "vue-sonner";
import { useScreenStore } from '@/stores/useScreenStore'
import DefaultModalForm from "@/components/Modal/DefaultModalForm.vue"
import { ForgotPassword } from '@/api/services/UserServiceFunctions';

const screenStore = useScreenStore();

// Initialize the screen size listener
screenStore.initializeScreenSizeListener();

const isLargeScreen = screenStore.isLargeScreen;

const user = ref(null);
const email = ref('');
const password = ref('');
const open = ref(false)
const valueInput = ref(null)

const setOpen = (value) => {
  open.value = value;
}

const forgotPassword = () => {
  ForgotPassword(valueInput.value)
}

const login = async () => {
  const credentials = { username: email.value, password: password.value };

  toast.promise(
    authService.login(credentials),
    {
      loading: 'Chargement...',
      success: (data) => {
        user.value = data;
        router.push({ path: '/dashboard' });
        return `Connexion réussie pour ${data.username}`;
      },
      error: (error) => {
        console.error('Login failed:', error);
        return `Échec de la connexion : identifiant ou mot de passe incorrect.`;
      },
    })
};
</script>
