<template>
    <DefaultAppLayout>
        <div class="flex flex-col p-5 space-y-3">
            <div class="flex justify-between">
                <h2 class="font-bold tracking-tight text-gray-900 text-22">Paramètres de l'application</h2>
                <div class="w-32">
                    <DefaultActionBtn title="Déconnection" :onClick="authService.logout" />
                </div>
            </div>
            <DefaultBox customClass="w-full mt-3">
                <DefaultObjectifSection :benefitMonth="benefitMonth" :categoryMonth="categoryMonth" />
            </DefaultBox>
            <div class="flex flex-col space-y-3 xl:flex-row xl:space-x-3  xl:space-y-0 items-baseline">
                <InformationUser />
                <div class="w-full xl:w-1/3">
                    <UrsafUserInformation />
                </div>
                <SubscriptionUser />
            </div>
        </div>
    </DefaultAppLayout>
</template>


<script setup>
import { ref, onMounted } from 'vue'
import { toast } from "vue-sonner";
import DefaultAppLayout from '@/components/Layout/DefaultAppLayout.vue'
import DefaultObjectifSection from '@/components/Other/DefaultObjectifSection.vue'
import DefaultBox from '@/components/Layout/DefaultBox.vue'
import InformationUser from './components/InformationUser.vue'
import UrsafUserInformation from './components/UrsafUserInformation.vue'
import SubscriptionUser from './components/SubscriptionUser.vue'
import DefaultActionBtn from '@/components/Button/DefaultActionBtn.vue';
import authService from '@/api/services/authService';

import { GetAllViewBenefitMonth, GetAllViewBenefitMonthCategory } from '@/api/services/StatViewService'

const benefitMonth = ref([]);
const categoryMonth = ref([]);

const getData = async () => {
    try {
        const date = new Date()
        const nowMonth = date.getMonth() + 1
        const nowYears = date.getFullYear()

        // Call
        categoryMonth.value = await GetAllViewBenefitMonthCategory(`month=${nowMonth}&years=${nowYears}&order%5Bprice_value%5D=desc%5DitemsPerPage=4&page=1`);
        benefitMonth.value = await GetAllViewBenefitMonth(`years=${nowYears}&order%5Bdate_full%5D=desc`);
    } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue");
    }
}

onMounted(() => {
    getData()
})
</script>
