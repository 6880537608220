<template>
    <div class="py-24 sm:py-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl sm:text-center">
                <h2 class="text-base font-semibold leading-7 text-slate-700">Maker Copilot</h2>
                <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Fonctionnalités Clés de
                    Maker Copilot</p>
                <p class="mt-6 text-lg leading-8 text-gray-600">Découvrez comment Maker Copilot peut transformer la
                    gestion de votre entreprise</p>
            </div>
        </div>
        <div class="relative overflow-hidden pt-16">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
                <img :src="app_image" alt="App screenshot"
                    class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10" width="2432" height="1442" />
                <div class="relative" aria-hidden="true">
                    <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-c_light_gray pt-[7%]" />
                </div>
            </div>
        </div>
        <div class="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
            <dl
                class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                <div v-for="feature in features" :key="feature.name" class="relative pl-9">
                    <dt class="inline font-semibold text-gray-900">
                        <component :is="feature.icon" class="absolute left-1 top-1 h-5 w-5 text-purple-600"
                            aria-hidden="true" />
                        {{ feature.name }}
                    </dt>
                    {{ ' ' }}
                    <dd class="inline">{{ feature.description }}</dd>
                </div>
            </dl>
        </div>
    </div>
</template>

<script setup>
import {
    ChartPieIcon,
    PresentationChartLineIcon,
    ArrowTrendingUpIcon,
    HandRaisedIcon,
    PresentationChartBarIcon,
    CursorArrowRaysIcon,
} from '@heroicons/vue/20/solid'

import app_image from "../../../../../public/img/app_img.webp"

const features = [
    {
        name: 'Analyse des Bénéfices',
        description: 'Identifiez les opportunités d\'optimisation de vos marges grâce à des rapports détaillés.',
        icon: PresentationChartBarIcon,
    },
    {
        name: 'Objectifs de Croissance Ambitieux',
        description: 'Fixez des objectifs personnalisés et suivez votre progression pour rester sur la voie du succès.',
        icon: PresentationChartLineIcon,
    },
    {
        name: 'Statistiques Détaillées',
        description: 'Accédez à des tableaux de bord sur mesure et obtenez des insights précis pour prendre des décisions informées.',
        icon: ChartPieIcon,
    },
    {
        name: 'Simplification de la Gestion d\'Entreprise',
        description: 'Automatiser les tâches répétitives et centraliser toutes vos données en un seul endroit pour gagner du temps.',
        icon: HandRaisedIcon,
    },
    {
        name: 'Suivi des Ventes en Temps Réel',
        description: 'Visualisez instantanément vos performances commerciales et ajustez vos stratégies en conséquence.',
        icon: ArrowTrendingUpIcon,
    },
    {
        name: 'Interface Intuitive et Facile à Utiliser',
        description: 'Profitez d\'une interface utilisateur conviviale qui simplifie la gestion quotidienne de votre entreprise.',
        icon: CursorArrowRaysIcon,
    },
]
</script>