<template>
    <DefaultAppLayout>
        <DefaultModalForm :open="open" :setOpen="setOpen" :action="createData">
            <div class="mt-3 text-center">
                <DialogTitle as="h3" class="font-semibold leading-6 text-gray-900 text-24">
                    {{ modalData.title }}
                </DialogTitle>
                <div class="w-full py-7">
                    <label for="email-address" class="block text-sm font-medium text-left text-gray-700">
                        {{ modalData.subTitle }}
                    </label>
                    <div class="mt-2">
                        <input type="text" name="nom" autocomplete="off" v-model="valueInput"
                            class="block w-full border-gray-300 rounded-md shadow-sm focus:border-purple-500 focus:ring-purple-500 text-10" />
                    </div>
                </div>
            </div>
        </DefaultModalForm>
        <div class="p-5 space-y-3">
            <h2 class="font-bold tracking-tight text-gray-900 text-22">Toutes vos données</h2>
            <div class="">
                <div class="flex items-center justify-between">
                    <h3 class="font-bold tracking-tight text-gray-900 text-16">Toutes vos catégories</h3>
                    <DefaultActionBtnReverse title="Créer une catégorie" :onClick="() => setOpenModal(1)" />
                </div>
                <DefaultTableCli :datas="categoryDatas" :type="1" />
            </div>
            <div class="flex xl:flex-row flex-col items-baseline justify-between xl:space-x-3  xl:space-y-0 space-y-3">
                <div class="w-full">
                    <div class="flex items-center justify-between">
                        <h3 class="font-bold tracking-tight text-gray-900 text-16">Toutes vos canaux de ventes</h3>
                        <DefaultActionBtnReverse title="Créer une vente" :onClick="() => setOpenModal(2)" />
                    </div>
                    <DefaultTableCli :datas="salesChannelsDatas" :type="2" />
                </div>
                <div class="w-full">
                    <div class="flex items-center justify-between">
                        <h3 class="font-bold tracking-tight text-gray-900 text-16">Toutes vos clients</h3>
                        <DefaultActionBtnReverse title="Créer un client" :onClick="() => setOpenModal(3)" />
                    </div>
                    <DefaultTableCli :datas="clientDatas" :type="3" />
                </div>
            </div>

        </div>
    </DefaultAppLayout>
</template>

<script setup>
import { ref, onMounted } from 'vue';

import DefaultAppLayout from '@/components/Layout/DefaultAppLayout.vue'
import DefaultActionBtnReverse from '@/components/Button/DefaultActionBtnReverse.vue'
import { GetAllSalesChannelData, CreateSalesChannel } from '@/api/services/SalesChannelServiceFunctions'
import { GetAllClientData, CreateClient } from '@/api/services/ClientServiceFunctions'
import { GetAllCategoryData, CreateCategory } from '@/api/services/CategoryServiceFunctions'
import DefaultTableCli from '@/views/private/Client/components/DefaultTableCli.vue'
import DefaultModalForm from "@/components/Modal/DefaultModalForm.vue"
import authService from "@/api/services/authService";

const user = authService.getUser();

const categoryDatas = ref([]);
const clientDatas = ref([]);
const salesChannelsDatas = ref([]);
const modalData = ref({
    title: "",
    subTitle: "",
    type: "",
});

const open = ref(false)
const valueInput = ref(null)

const setOpen = (value) => {
    open.value = value;
}

const createData = async () => {
    const data = {
        name: valueInput.value,
        user: "api/users/" + user.id,
    }
    switch (modalData.value.type) {
        case 1:
            await CreateCategory(data)
            break;
        case 2:
            await CreateSalesChannel(data)
            break;
        case 3:
            await CreateClient(data)
            break;
        default:
            alert('Une erreur est survenue')
    }
    open.value = false;
    fetchSales()
}

const fetchSales = async () => {
    categoryDatas.value = await GetAllCategoryData();
    salesChannelsDatas.value = await GetAllSalesChannelData();
    clientDatas.value = await GetAllClientData();
};

const setOpenModal = (type) => {
    switch (type) {
        case 1:
            modalData.value.title = "Créer une catégories",
                modalData.value.subTitle = "Nom de la catégories"
            break;
        case 2:
            modalData.value.title = "Créer un canal de vente",
                modalData.value.subTitle = "Nom du canal de vente"
            break;
        case 3:
            modalData.value.title = "Créer une client",
                modalData.value.subTitle = "Nom du client"
            break;
    }
    modalData.value.type = type
    open.value = true;
}

onMounted(() => {
    fetchSales();
});
</script>