<template>
    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-50">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 transition-opacity bg-slate-200 bg-opacity-90">
                    <div class="relative isolate">
                        <svg class="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-white [mask-image:radial-gradient(70rem_70rem_at_center,white,transparent)]"
                            aria-hidden="true">
                            <defs>
                                <pattern id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84" width="60" height="60" x="50%" y="-1"
                                    patternUnits="userSpaceOnUse">
                                    <path d="M.5 200V.5H200" fill="none" />
                                </pattern>
                            </defs>
                            <rect width="100%" height="100%" stroke-width="0"
                                fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
                        </svg>
                        <div class="absolute top-0 right-0 w-full -ml-24 overflow-hidden left-1/2 -z-10 transform-gpu blur-3xl lg:ml-24 xl:ml-48"
                            aria-hidden="true">
                            <div class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#E1FF72] to-[#E1BEF6] opacity-60"
                                style="clip-path: polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)" />
                        </div>
                    </div>
                </div>
            </TransitionChild>

            <div class="fixed inset-0 z-10 xl:w-screen w-[100%] overflow-y-auto">
                <div class="flex items-end justify-center w-[100%] min-h-full text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative w-[70%] right-auto left-auto px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl xl:w-2/3">
                            <NewUserModalStep1 v-if="pageItem === 1" />
                            <NewUserModalStep2 v-if="pageItem === 2" />
                            <NewUserModalStep3 :updatePageItem="updatePageItem" v-if="pageItem === 3" />
                            <NewUserModalStep4 v-if="pageItem === 4" :updateOpen="updateOpen" />
                            <nav class="flex items-center justify-between px-4 mt-10 border-t border-gray-200 sm:px-0">
                                <div class="flex flex-1 w-0 -mt-px">
                                    <button @click="pageItem--" v-if="pageItem !== 1"
                                        class="inline-flex items-center pt-4 pr-1 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:border-gray-300 hover:text-gray-700">
                                        <ArrowLongLeftIcon class="w-5 h-5 mr-3 text-gray-400" aria-hidden="true" />
                                        Retour
                                    </button>
                                </div>
                                <div class="flex justify-end flex-1 w-0 -mt-px">
                                    <button @click="pageItem++" v-if="pageItem !== 4"
                                        class="inline-flex items-center pt-4 pl-1 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:border-gray-300 hover:text-gray-700">
                                        Suivant
                                        <ArrowLongRightIcon class="w-5 h-5 ml-3 text-gray-400" aria-hidden="true" />
                                    </button>
                                </div>
                            </nav>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/vue/20/solid'

import NewUserModalStep1 from '@/components/Other/NewUserModal/NewUserModalStep1.vue'
import NewUserModalStep2 from '@/components/Other/NewUserModal/NewUserModalStep2.vue'
import NewUserModalStep3 from '@/components/Other/NewUserModal/NewUserModalStep3.vue'
import NewUserModalStep4 from '@/components/Other/NewUserModal/NewUserModalStep4.vue'

const pageItem = ref(1)
const open = ref(true)

const updatePageItem = () => {
    pageItem.value++
}

const updateOpen = () => {
    open.value = false
}
</script>