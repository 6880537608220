<template>
    <div v-if="isLargeScreen" class="flex justify-between w-[98.9vw] min-h-screen">
        <DefaultAppSideBarNew />
        <main class="w-full h-full min-h-screen py-3 ml-24">
            <slot></slot>
        </main>
        <button type="button" @click="setOpen(true)"
        class="rounded-full fixed bottom-5 right-5 bg-purple-600 p-3 text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        <ChatBubbleBottomCenterTextIcon class="h-5 w-5" aria-hidden="true" />
    </button>
    </div>
    <div v-else>
        <div class="w-screen min-h-screen bg-c_light_gray xl:pb-0 pb-52">
            <main class="w-full h-full min-h-screen p-3">
                <slot></slot>
            </main>
        </div>

        <DefaultAppSideBarNew />
    </div>
    <DefaultModalForm :open="open" :setOpen="setOpen" :action="submitForm" mainBtnTitle="Envoyer">
        <div class="mt-3 text-center">
            <DialogTitle as="h3" class="font-semibold leading-6 text-gray-900 text-24">
                Partagez votre avis
            </DialogTitle>
            <p class="text-sm text-center text-slate-800">
                Aidez-nous à améliorer l'application en nous indiquant comment elle pourrait mieux répondre à vos
                besoins.
            </p>
            <div class="w-full py-7">
                <label for="type" class="block text-sm font-medium text-left text-gray-700">
                    Type de retour*
                </label>
                <div class="mt-2">
                    <select id="type" name="type" v-model="form.type" required
                        class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-purple-600 sm:text-sm sm:leading-6">
                        <option value="💡 Nouvelle fonctionnalité ou amélioration">💡 Nouvelle fonctionnalité ou
                            amélioration</option>
                        <option value="🐛 Signaler un bug ou une erreur">🐛 Signaler un bug ou une erreur</option>
                        <option value="📬 Donner un avis ou retour d'expérience">📬 Donner un avis ou retour
                            d'expérience</option>
                        <option value="🤔 Poser une question">🤔 Poser une question</option>
                    </select>
                </div>
            </div>
            <div class="w-full py-7">
                <label for="message" class="block text-sm font-medium text-left text-gray-700">
                    Votre message*
                </label>
                <div class="mt-2">
                    <textarea id="message" name="message" v-model="form.message" required
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm"
                        placeholder="Expliquez-nous en détail votre retour ou votre suggestion..."></textarea>
                </div>
            </div>
        </div>
    </DefaultModalForm>

</template>

<script setup>
import { ref } from 'vue'

import DefaultAppSideBarNew from '../Nav/DefaultAppSideBarNew.vue'
import { useScreenStore } from '@/stores/useScreenStore'
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/vue/20/solid'
import DefaultModalForm from "@/components/Modal/DefaultModalForm.vue"
import authService from '@/api/services/authService';

import axios from "@/api/config";
import { toast } from "vue-sonner";

const screenStore = useScreenStore();
const open = ref(false)

const form = ref({
    user_id: authService.getUser().id,
    user_email: authService.getUser().username,
    type: '',
    message: ''
});

const setOpen = (value) => {
    open.value = value;
}


// Initialize the screen size listener
screenStore.initializeScreenSizeListener();

const isLargeScreen = screenStore.isLargeScreen;


// Fonction pour soumettre le formulaire
const submitForm = async () => {
    // Validation basique avant l'envoi
    if (!form.value.type || !form.value.message) {
        toast.error('Veuillez remplir tous les champs obligatoires.');
        return;
    }

    try {
        // Envoyer les données via l'API
        await axios.post("/feedback", {
            user_id: form.value.user_id,
            user_email: form.value.user_email,
            type: form.value.type,
            message: form.value.message
        });

        // Réinitialiser le formulaire après l'envoi
        form.value.user_id = '';
        form.value.user_email = '';
        form.value.type = '';
        form.value.message = '';

        open.value = false;

        toast.success("Votre retour a été envoyée avec succès !");
    } catch (error) {
        toast.error("Une erreur est survenue lors de l'envoi du formulaire.");
    }
};
</script>